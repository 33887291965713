import React from 'react';

import { Text, Tooltip } from '@chakra-ui/react';
import PropTypes from 'prop-types';

/**
 * Wrapper for hover tooltip component
 *
 * @param {string} targetId - must be match the id of the targetElement
 * @param {string} text - text shown on hover
 * @param {string} placement - placement of the hover text
 * @param {ReactElement} children - any desired React element, make sure this contains an id that matches the target param
 * @returns Icon Hover tooltip component
 */
const TooltipWrapper = ({
  children,
  disableTooltip = false,
  text,
  placement = 'top',
  childDisplay = 'inline-block',
  ...props
}) => {
  return (
    <Tooltip
      placement={placement}
      hasArrow
      label={text || props.label}
      isDisabled={disableTooltip || props.isDisabled}
      shouldWrapChildren
      mx="2"
    >
      <Text as="span" m="0" display={childDisplay}>
        {children}
      </Text>
    </Tooltip>
  );
};

TooltipWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  disableTooltip: PropTypes.bool,
  text: PropTypes.string,
  placement: PropTypes.string,
};

export default TooltipWrapper;

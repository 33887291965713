import React, { useMemo } from 'react';

import { useFormControlContext, useTheme } from '@chakra-ui/react';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { ClearIndicator } from './ClearIndicator';
import { DropdownIndicator } from './DropdownIndicator';
import { MultiValueRemove } from './MultiValueRemove';

const getBorderStyles = (state, colors) => {
  if (state.isFocused) {
    return colors.gray[300];
  } else if (state.selectProps.isInvalid) {
    return colors.red[500];
  }

  return colors.grey[200];
};

function useDropDownStyles() {
  const theme = useTheme();
  const {
    colors,
    space,
    transition,
    radii,
    fontSizes,
    fontWeights,
    lineHeights,
  } = theme;
  const styles = useMemo(() => {
    return {
      control: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        padding: 0,
        marginTop: '0px',
        borderColor: getBorderStyles(state, colors),
        boxShadow: 'none',
        backgroundColor: state.isDisabled ? colors.gray[50] : colors.gray[0],
        transitionDuration: transition.duration.normal,
        transitionProperty:
          'background-color, border-color, color, fill, stroke, opacity, box-shadow, transform',
        ':hover': {
          borderColor: colors.gray[300],
        },
        ':active': {
          borderColor: colors.purple[300],
        },
        ':focus': {
          borderColor: colors.purple[300],
        },
        height: state.selectProps.size === 'sm' ? '32px' : 'auto',
        minHeight: state.selectProps?.size === 'sm' ? '32px' : '38px',
        borderRadius:
          state.selectProps.style === 'menu' ? radii['sm'] : radii['base'],
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        padding: state.selectProps.isMulti
          ? `${space['mg1']} ${space[1]} ${space['mg1']} ${space[3]}`
          : state.selectProps.size === 'sm'
          ? `${space[0]} ${space[1]} ${space['mg1']} ${space[3]}`
          : `${space[2]} ${space[1]} ${space[2]} ${space[3]}`,
        minHeight: state.selectProps?.size === 'sm' ? '26px' : '38px',
        height: state.selectProps.isMulti
          ? 'auto'
          : state.selectProps?.size === 'sm'
          ? '26px'
          : '38px',
      }),
      input: () => ({
        visibility: 'visible',
        fontSize: fontSizes[6],
        lineHeight: lineHeights[5],
        fontWeight: fontWeights[5],
        color: colors.gray[900],
        margin: `0 ${space['mg0']} 0 0`,
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        maxHeight: state.selectProps.size === 'sm' ? '32px' : '38px',
        padding: `0 ${space[2]} 0 ${space[1]}`,
      }),
      indicatorSeparator: (provided, state) => ({
        // state doesn't behave how we want it to without passing provided
        width: '1px',
        height: '16px',
        backgroundColor: state.isDisabled ? 'transparent' : colors.gray[300],
        visibility: state.selectProps.style === 'menu' && 'hidden',
      }),
      clearIndicator: (provided) => ({
        ...provided,
        color: colors.gray[300],
        ':hover': {
          color: colors.gray[300],
        },
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? colors.gray[300] : colors.gray[900],
        ':hover': {
          color: 'initial',
        },
      }),
      placeholder: (provided, state) => ({
        ...provided,
        position: 'absolute',
        fontSize:
          state.selectProps?.size === 'sm' ? fontSizes[7] : fontSizes[6],
        lineHeight:
          state.selectProps?.size === 'sm' ? lineHeights[1] : lineHeights[5],
        fontWeight: fontWeights[1],
        color: colors.grey[400],
        marginLeft: '0',
      }),
      singleValue: (provided, state) => ({
        ...provided,
        position: 'absolute',
        fontSize:
          state.selectProps?.size === 'sm' ? fontSizes[7] : fontSizes[6],
        lineHeight:
          state.selectProps?.size === 'sm' ? lineHeights[1] : lineHeights[5],
        fontWeight: fontWeights[5],
        color: colors.grey[900],
      }),
      multiValue: (provided) => ({
        ...provided,
        margin: `${space['mg0']} ${space[1]} ${space['mg0']} 0`,
        maxWidth: '100%',
        backgroundColor: colors.grey[50],
        boxShadow: `inset 0 0 0 1px ${colors.grey[300]}`,
        borderRadius: radii['base'],
        fontSize: '14px',
        lineHeight: lineHeights[7],
        fontWeight: fontWeights[7],
        color: colors.grey[900],
      }),
      multiValueLabel: () => ({
        textOverflow: 'initial',
        whiteSpace: 'initial',
        padding: `${space['mg0']} ${space['mg0']} ${space['mg0']} ${space['mg1']}`,
      }),
      multiValueRemove: (provided) => ({
        ...provided,
        padding: `${space['mg0']} ${space['mg1']} ${space['mg0']} ${space['mg0']}`,
        ':hover': {
          backgroundColor: colors.red[100],
          boxShadow: `inset 0 0 0 1px ${colors.red[300]}`,
          borderRadius: radii['base'],
          borderTopLeftRadius: '0',
          borderBottomLeftRadius: '0',
          color: colors.red[700],
          cursor: 'pointer',
        },
      }),
      menu: (provided, state) => ({
        ...provided,
        backgroundColor: colors.grey[0],
        cursor: 'pointer',
        marginTop: space[1],
        marginBottom: '0',
        boxShadow: 'none',
        fontSize: fontSizes[6],
        width: state.selectProps.fitContent ? 'max-content' : '100%',
        minWidth: '100%',
      }),
      menuList: (provided) => ({
        ...provided,
        padding: '0',
        fontSize: fontSizes[6],
        border: `1px solid ${colors.grey[300]}`,
        borderRadius: radii['base'],
        '& > :first-of-type': {
          borderTopRightRadius: '4px',
          borderTopLeftRadius: '4px',
        },
        '& > :last-child': {
          borderBottomRightRadius: '4px',
          borderBottomLeftRadius: '4px',
        },
        height: 'auto',
      }),
      option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        padding: `${space['mg2']} ${space[3]}`,
        color: colors.grey[900],
        backgroundColor: state.isFocused ? 'transparent' : 'transparent',
        ':focus': {
          borderColor: colors.purple[300],
        },
        ':active': {
          borderColor: colors.purple[300],
        },
        ':hover': {
          color: colors.grey[900],
          backgroundColor: colors.grey[50],
        },
        fontSize:
          state.selectProps?.size === 'sm' ? fontSizes[6] : fontSizes[5],
      }),
    };
  }, [theme]);
  return styles;
}

export function Dropdown({ ...props }) {
  const field = useFormControlContext();
  const customStyles = useDropDownStyles();

  let fieldProps = {};
  if (field) {
    fieldProps.isDisabled = field.isReadOnly;
    fieldProps.isRequired = field.isRequired;
    fieldProps.isInvalid = field.isInvalid;
  }

  return (
    <Select
      {...props}
      {...fieldProps}
      components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
      styles={customStyles}
    />
  );
}

export function AsyncDropdown({ ...props }) {
  const field = useFormControlContext();
  const customStyles = useDropDownStyles();

  let fieldProps = {};
  if (field) {
    fieldProps.isDisabled = field.isReadOnly;
    fieldProps.isRequired = field.isRequired;
    fieldProps.isInvalid = field.isInvalid;
  }

  return (
    <AsyncSelect
      {...props}
      {...fieldProps}
      components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
      styles={customStyles}
    />
  );
}

export function AsyncPaginateDropdown({ ...props }) {
  const field = useFormControlContext();
  const customStyles = useDropDownStyles();

  let fieldProps = {};
  if (field) {
    fieldProps.isDisabled = field.isReadOnly;
    fieldProps.isRequired = field.isRequired;
    fieldProps.isInvalid = field.isInvalid;
  }

  return (
    <AsyncPaginate
      {...props}
      {...fieldProps}
      components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
      styles={customStyles}
    />
  );
}

export function AsyncCreatableDropdown({ ...props }) {
  const field = useFormControlContext();
  const customStyles = useDropDownStyles();

  let fieldProps = {};
  if (field) {
    fieldProps.isDisabled = field.isReadOnly;
    fieldProps.isRequired = field.isRequired;
    fieldProps.isInvalid = field.isInvalid;
  }

  return (
    <AsyncCreatableSelect
      {...props}
      {...fieldProps}
      components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
      styles={customStyles}
    />
  );
}

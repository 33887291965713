import firehydrantAPI from 'helpers/firehydrantAPI';
import internalAPI from 'helpers/internalAPI';
import { createMutationHook, useApiQuery } from 'queries/common';

export const useSignals = (params = {}) => {
  const query = useApiQuery(['signals', 'list', params], () =>
    firehydrantAPI.get('signals', { params }),
  );
  return {
    ...query,
    data: query.data?.data?.data ?? [],
    pagination: query.data?.data?.pagination ?? {},
  };
};

export const useCreateSignal = (url) =>
  createMutationHook((params) => firehydrantAPI.post(url, params))();

export const useSignalDebugger = (options) =>
  createMutationHook((params) =>
    firehydrantAPI.post('signals/debugger', params),
  )(options);

export const useSignalCurlUrl = () => {
  const query = useApiQuery(['signal_curl_api', 'detail', 'new'], () =>
    internalAPI.get('/internal_api/v1/onboarding/signals/ingest_url'),
  );
  return {
    ...query,
    data: query.data?.data ?? {},
  };
};

import React, { useEffect, useState } from 'react';

import { Flex, Link, Text } from '@firehydrant/design-system';

import EnvironmentBadge from './EnvironmentBadge';
import ToolbarText from './ToolbarText';

const Environment = () => {
  const [versions, setVersions] = useState({});
  const versionCheck = async () => {
    try {
      const body = await fetch('/infra/health').then((response) =>
        response.json(),
      );
      setVersions({
        rubyVersion: body.ruby_version,
        railsVersion: body.rails_version,
        revision: body.revision,
      });
    } catch (e) {
      console.error('Error fetching version info', e);
    }
  };
  useEffect(() => {
    versionCheck();
    const versionInterval = setInterval(versionCheck, 120000);

    return () => clearInterval(versionInterval);
  }, []);

  const reactVersion = React.version;
  const gitRevision = versions?.revision;

  return (
    <Flex alignItems="center" gap={3}>
      <EnvironmentBadge />
      <ToolbarText>
        Ruby{' '}
        <Text as="span" fontFamily="mono" color="gray.50">
          {versions?.rubyVersion}
        </Text>
      </ToolbarText>
      <ToolbarText>
        Rails{' '}
        <Text as="span" fontFamily="mono" color="gray.50">
          {versions?.railsVersion}
        </Text>
      </ToolbarText>
      <ToolbarText>
        React{' '}
        <Text as="span" fontFamily="mono" color="gray.50">
          {reactVersion}
        </Text>
      </ToolbarText>
      <ToolbarText>
        Rev&nbsp;
        <Link
          href={`https://github.com/firehydrant/laddertruck/commits/${gitRevision}`}
          fontSize="auto"
          target="_blank"
        >
          <ToolbarText
            as="span"
            display="inline"
            color="gray.50"
            textTransform="lowercase"
            textDecoration="underline"
            fontFamily="mono"
          >
            {gitRevision}
          </ToolbarText>
        </Link>
      </ToolbarText>
    </Flex>
  );
};

export default Environment;

import React, { useEffect, useState } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { Flex, Icon, Text } from '@firehydrant/design-system';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

import BackendTimingDisplay from './BackendTimingDisplay';
import TimingListItem from './TimingListItem';
import ToolbarPopover from './ToolbarPopover';
import ToolbarText from './ToolbarText';

const Timing = ({ barRef }) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  const [requestStart, setRequestStart] = useState(0);
  const [stack, setStack] = useState([]);
  const [backendStack, setBackendStack] = useState([]);

  const observe = new PerformanceObserver((list) => {
    list.getEntries().forEach((entry) => {
      // Only collect XHR requests
      if (entry.initiatorType === 'xmlhttprequest') {
        // Check if request is a cache hit (zero request time), and if so ignore it
        const request = entry.responseStart - entry.requestStart;
        if (request > 0) {
          setStack((stack) => [...stack, entry]);

          if (entry.serverTiming[0]) {
            setBackendStack((backendStack) => [
              ...backendStack,
              JSON.parse(window.atob(entry.serverTiming[0].description)),
            ]);
          }
        }
      }
    });
  });

  function handleDocumentClick(event) {
    // Only handle clicks within the main app using the reference on the
    // toolbar component, clicking in the tool bar will not clear the timing data
    if (barRef.current && !barRef.current.contains(event.target)) {
      setRequestStart(performance.now());
      setStack([]);
      setBackendStack([]);
    }
  }

  useEffect(() => {
    setRequestStart(performance.now());
    observe.observe({ type: 'resource', buffered: true });
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  // Frontend performance timing metrics
  const requests = stack.length;
  const duration =
    (stack[stack.length - 1]?.responseEnd || requestStart) - requestStart;
  const transferred = stack.reduce(
    (total, event) => total + event.transferSize,
    0,
  );

  function formatNumber(num) {
    return num.toLocaleString('en-US', { maximumFractionDigits: 0 });
  }

  const requestList = stack.map((entry, index) => (
    <TimingListItem key={index} entry={entry} />
  ));

  const RequestCount = () => {
    return (
      <>
        <Text as="span" color="white" fontWeight="bold">
          {requests}
        </Text>
        &nbsp;reqs
      </>
    );
  };

  return (
    <>
      <Flex
        backgroundColor="gray.600"
        border="1px solid"
        borderColor="gray.600"
        px="2"
        py="1"
        borderTopLeftRadius="md"
        borderBottomLeftRadius="md"
      >
        <ToolbarText color="white" fontWeight="1">
          API
        </ToolbarText>
      </Flex>
      <Flex
        gap={2}
        alignItems="center"
        border="1px solid"
        borderColor="gray.600"
        px="2"
        py="1"
        backgroundColor="gray.1000"
        display={isOpen ? 'flex' : 'none'}
        fontFamily="mono"
      >
        <ToolbarText>FE</ToolbarText>
        <ToolbarText as="div">
          <ToolbarPopover trigger={<RequestCount />}>
            {requestList.length ? (
              requestList
            ) : (
              <Text m={0} color="grey.300" fontSize="xs">
                Nothing to see here
              </Text>
            )}
          </ToolbarPopover>
        </ToolbarText>
        <ToolbarText>
          <Text as="span" color="white" fontWeight="bold">
            {formatNumber(duration)}ms
          </Text>
          &nbsp;dur
        </ToolbarText>
        <ToolbarText>
          <Text as="span" color="white" fontWeight="bold">
            {formatNumber(transferred / 1000)}k
          </Text>
          &nbsp;data
        </ToolbarText>
        <ToolbarText>|</ToolbarText>
        <ToolbarText fontWeight="bold">BE</ToolbarText>
        <BackendTimingDisplay data={backendStack} />
      </Flex>
      <Flex
        alignItems="center"
        backgroundColor="gray.600"
        px="0"
        py="1"
        borderTopRightRadius="md"
        borderBottomRightRadius="md"
        onClick={(e) => {
          e.stopPropagation();
          onToggle();
        }}
      >
        <Icon as={isOpen ? BiChevronLeft : BiChevronRight} boxSize="16px" />
      </Flex>
    </>
  );
};

export default Timing;

import React, { useState } from 'react';

import { Flex, IconButton, Input, Text } from '@chakra-ui/react';
import { Copy } from '@phosphor-icons/react';
import { useUserContext } from 'contexts/UserContext/UserContextProvider';

import { useToastContext } from 'components/common/ToastBannerProvider/ToastBannerProvider';

const SignalsCalendarSubscriptionSelector = () => {
  const {
    currentOrganization: { icalUrl },
  } = useUserContext();
  const { addToast } = useToastContext();

  const handleCopy = (url) => () => {
    navigator.clipboard.writeText(url);
    addToast({
      message: 'Copied to clipboard',
      variant: 'success',
    });
  };

  return (
    <Flex flexDir="column" mt="5">
      <Flex
        flexDir="column"
        backgroundColor="gray.50"
        p="3"
        borderRadius="xs"
        mb="4"
      >
        <Text mb="1" color="gray.700">
          Calendar Subscription URL
        </Text>
        <Text as="small">
          This URL is your personal calendar subscription URL for your on-call
          shifts in this organization. You can use it to subscribe to your
          on-call shifts in your calendar application of choice.
        </Text>
        <Flex mt="3" maxWidth="700px">
          <Input
            data-testid="copy"
            onClick={handleCopy(icalUrl)}
            value={icalUrl}
            readOnly
            cursor="pointer"
            fontSize="6"
          />
          <IconButton
            onClick={handleCopy(icalUrl)}
            size="md"
            variant="ghost"
            icon={<Copy size="16px" />}
            title="Copy"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SignalsCalendarSubscriptionSelector;

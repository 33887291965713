import React from 'react';

import { Flex, Text, useDisclosure } from '@chakra-ui/react';
import { useCookies } from 'react-cookie';

import ConfirmModal from 'components/modals/ConfirmModal';

const LocalPreview = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [cookies, setCookie, removeCookie] = useCookies(['laddertruck']);
  const cookieKey = 'use_local_dev';
  const preview = cookies[cookieKey];

  const handlePreview = async () => {
    if (preview) {
      await removeCookie(cookieKey, { path: '/' });
    } else {
      await setCookie(cookieKey, true, { path: '/' });
    }

    const url = new window.URL(window.location);
    window.location = url;
  };

  if (window.firehydrantBaseAPIPath?.includes('local')) {
    return null;
  }

  return (
    <>
      <ConfirmModal
        modalBody="Are you sure? You should only be in this mode if you are trying to debug a Production issue. Do not use this for development."
        onConfirm={() => handlePreview()}
        isOpen={isOpen}
        onCancel={onClose}
        headerText={`Use Local Dev in Production`}
      />
      <Flex
        ml="2"
        variant="outline"
        background="grey.50"
        color="grey.600"
        textTransform="none"
        fontWeight="500"
        px="2"
        py="1"
        borderRadius="40px"
        cursor="pointer"
        onClick={() => {
          if (window.firehydrantBaseAPIPath?.includes('staging') || preview) {
            handlePreview();
          } else {
            onOpen();
          }
        }}
      >
        <Text mb="0" fontSize="12px">
          Local Dev:{' '}
          {preview ? (
            <Text
              mb="0"
              as="span"
              bg="green.500"
              textColor="white"
              borderRadius="2xl"
              px="2"
              py="0.5"
            >
              On
            </Text>
          ) : (
            <Text
              mb="0"
              as="span"
              bg="red.500"
              textColor="white"
              borderRadius="2xl"
              px="2"
              py="0.5"
            >
              Off
            </Text>
          )}
        </Text>
      </Flex>
    </>
  );
};

export default LocalPreview;

import React from 'react';

import { Link, MenuItem } from '@chakra-ui/react';
import { Icon } from '@firehydrant/design-system';

const InternalToolLink = ({ href, name, iconType }) => {
  return (
    <MenuItem alignItems="baseline">
      <Link
        color="grey.900"
        href={href}
        target="_blank"
        size="7"
        _hover={{ textDecoration: 'none' }}
      >
        <Icon color="grey.900" as={iconType} boxSize="14px" mr="2" />
        {name}
      </Link>
    </MenuItem>
  );
};

export default InternalToolLink;

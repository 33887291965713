import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { ArrowLeft, ArrowRight } from '@phosphor-icons/react';
import { Form, Formik } from 'formik';
import { getAPIError } from 'helpers/firehydrantAPI';
import { useOrgNavigate } from 'hooks/useOrgNavigate';
import { useOnboardingStep } from 'queries/onboardingTasks';
import { useTeam } from 'queries/teams';
import { useEscalationPolicies } from 'queries/teams/signals/escalationPolicies';
import {
  useCreateSignalRules,
  useSignalRule,
  useUpdateSignalRules,
} from 'queries/teams/signals/rules';
import { useParams } from 'react-router-dom';

import AutomationWarning from 'components/common/AutomationWarning';
import Page404 from 'components/common/Page404';
import { useToastContext } from 'components/common/ToastBannerProvider/ToastBannerProvider';
import ConfirmModal from 'components/modals/ConfirmModal';

import {
  buildInitialRulesValues,
  ruleCreateValidationSchema,
} from '../signalsFormHelpers';
import ScheduleStep from './OnCallSchedules/ScheduleStep';
import { RuleDetailsPanel } from './RuleDetailsPanel';
import { RuleExpressionPanel } from './RuleExpressionPanel';
import { RuleTargetPanel } from './RuleTargetPanel';

const RuleCreateForm = () => {
  const { id, ruleId } = useParams();
  const [isCanceling, setIsCanceling] = useState(false);
  const { isLoading, error } = useTeam(id);

  const navigate = useOrgNavigate();
  const { addToast } = useToastContext();
  const [tabIndex, setTabIndex] = useState(0);
  const { isSignalsOnboarding, completeOnboardingStep, isStepComplete } =
    useOnboardingStep('create_signals_rule');
  const { data: escalationPolicies } = useEscalationPolicies(id);
  const { data: rule } = useSignalRule({ teamId: id, ruleId });

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
      setTabIndex((prevTabIndex) => prevTabIndex + 1);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const onError = (err) => {
    const error = getAPIError(err);
    addToast({
      variant: 'alert',
      message: error,
    });
  };

  const onSuccess = (message) => {
    addToast({
      variant: 'success',
      message,
    });
    if (isSignalsOnboarding && !isStepComplete) {
      completeOnboardingStep();
    }
    if (isSignalsOnboarding) {
      navigate('/overview');
    } else {
      navigate(`/settings/teams/${id}/rules`);
    }
  };

  const { mutate: createRule, isSubmitting } = useCreateSignalRules({
    onSuccess: () => {
      onSuccess('Your rule has been created.');
    },
    onError: (err) => {
      onError(err);
    },
  });

  const { mutate: updateRule } = useUpdateSignalRules({
    onSuccess: () => {
      onSuccess('Your rule has been updated.');
    },
    onError: (err) => {
      onError(err);
    },
  });

  function transformFormToAPIPayload(formValues, teamId) {
    const payload = {
      name: formValues.name || 'new rule',
      expression: formValues.expression,
      id: formValues.id,
      action: formValues.action,
      target_type: formValues.target_type,
      incident_type_id: formValues?.incidentType?.value || null,
      notification_priority_override:
        formValues?.notificationPriorityOverride?.value || '',
      teamId,
    };

    // e.g., escalationpolicy_target. We add this to make it easier to define the
    // radios with the correct value rather than needing to map them.
    // So the target_type can be `EscalationPolicy` without needing to name the radio
    // `EscalationPolicy_target`.
    const targetTypeField = `${formValues.target_type.toLowerCase()}_target`;

    payload.target_id = formValues[targetTypeField].value;

    return payload;
  }

  const handleSubmit = (values) => {
    const valuesForSubmit = transformFormToAPIPayload(values, id);

    if (ruleId) {
      updateRule(valuesForSubmit);
    } else {
      createRule(valuesForSubmit);
    }
  };

  const initialValues = buildInitialRulesValues(rule, escalationPolicies);

  if (error?.response?.status === 404) {
    return <Page404 />;
  }
  if (isLoading) return <div data-testid="loading"></div>;
  return (
    <Box width="100%">
      <Text as="h2" fontSize="4" fontWeight="400">
        {ruleId ? 'Update' : 'Create'} a rule
      </Text>
      <Formik
        validationSchema={ruleCreateValidationSchema}
        initialValues={initialValues}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ errors, touched }) => {
          return (
            <Form>
              <Flex flexDirection="column" flex="1" mt="5">
                <Tabs
                  onChange={(index) => setTabIndex(index)}
                  index={tabIndex}
                  w="100%"
                >
                  <TabList gap="2" w="100%" borderBottom="none" mb="3">
                    <ScheduleStep
                      name="Rule Filter"
                      hasError={'expression' in errors}
                      isComplete={tabIndex >= 0}
                      isCurrent={tabIndex === 0}
                    />
                    <ScheduleStep
                      hasError={
                        'target_type' in errors ||
                        'user_target' in errors ||
                        'escalationpolicy_target' in errors ||
                        'oncallschedule_target' in errors
                      }
                      name="Target"
                      isComplete={tabIndex >= 1}
                      isCurrent={tabIndex === 1}
                    />
                    <ScheduleStep
                      name="Name & Summary"
                      hasError={'name' in errors && touched.name}
                      isComplete={tabIndex >= 2}
                      isCurrent={tabIndex === 2}
                    />
                  </TabList>

                  <TabPanels pt="4">
                    <TabPanel p="0">
                      <RuleExpressionPanel />
                    </TabPanel>
                    <TabPanel p="0">
                      <RuleTargetPanel />
                    </TabPanel>
                    <TabPanel p="0">
                      <RuleDetailsPanel />
                    </TabPanel>
                  </TabPanels>
                  <Divider mt="80px" />
                  <Flex justifyContent="space-between" alignItems="center">
                    <ButtonGroup mt="4">
                      <Button
                        onClick={() => {
                          setIsCanceling(true);
                        }}
                        variant="ghost"
                      >
                        Cancel
                      </Button>
                      {tabIndex !== 0 && (
                        <Button
                          leftIcon={<ArrowLeft size={16} />}
                          variant="outline"
                          onClick={() => {
                            setTabIndex(tabIndex - 1);
                          }}
                        >
                          Back
                        </Button>
                      )}
                      {tabIndex !== 2 && (
                        <Button
                          rightIcon={<ArrowRight size={16} />}
                          onClick={() => {
                            setTabIndex(tabIndex + 1);
                          }}
                        >
                          Next
                        </Button>
                      )}
                      {tabIndex === 2 && (
                        <>
                          <Button
                            type="submit"
                            isLoading={isSubmitting}
                            isDisabled={isSubmitting}
                          >
                            {ruleId ? 'Update rule' : 'Create rule'}
                          </Button>
                        </>
                      )}
                    </ButtonGroup>
                    <AutomationWarning createdBy={rule?.created_by} />
                  </Flex>
                </Tabs>
              </Flex>
              {isCanceling && (
                <ConfirmModal
                  headerText="Are you sure?"
                  onCancel={() => setIsCanceling(false)}
                  buttonColor="button-danger"
                  modalBody="Any changes will be lost."
                  onConfirm={() => {
                    setIsCanceling(false);
                    navigate(`/settings/teams/${id}/rules`);
                  }}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default RuleCreateForm;

import React from 'react';

import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { trackEvent } from 'helpers/pawPrints';
import { useTeam } from 'queries/teams';
import { useEscalationPolicies } from 'queries/teams/signals/escalationPolicies';
import { useOnCallSchedules } from 'queries/teams/signals/onCallSchedules';
import { useParams } from 'react-router';

import FormV2 from 'components/common/Form/FormV2/FormV2';
import {
  FormikRadio,
  FormikRadioGroupV2,
} from 'components/common/Form/FormikRadioGroupV2';
import OrgRouteLink from 'components/common/OrgRouteLink';

import TeamAddMembersForm from './TeamAddMembersForm';

export const RuleTargetPanel = () => {
  const formik = useFormikContext();
  const { id } = useParams();
  const { data: escalationPolicies } = useEscalationPolicies(id);
  const { data: schedules } = useOnCallSchedules(id);
  const { data: team } = useTeam(id);

  const userMembers = team?.memberships?.filter((user) => user.user);

  return (
    <>
      <Box>
        <Text as="p" fontWeight="400" mb="4">
          Choose a target to notify if the rule is matched to an incoming event.
        </Text>
      </Box>
      <Flex>
        <Flex maxWidth="50%" direction="column" gap="3" >
          <FormikRadioGroupV2
            name="target_type"
            onChange={(val) => {
              trackEvent({
                name: 'Signals',
                params: {
                  page: 'Alert Rules',
                  event: `User clicks a target type  ${val}`,
                },
              });
            }}
          >
            {({ getRadioProps }) => (
              <Flex
                flexDirection={'column'}
                flex="1"
                w={'321px'}
                pr={'40px'}
                mb="4"
                gap="2"
              >
                <FormikRadio
                  label="Escalation Policy"
                  helpText="Send to an escalation policy"
                  dataTestId="escalation-policy"
                  {...getRadioProps({ value: 'EscalationPolicy' })}
                />
                <FormikRadio
                  label="On-call Schedule"
                  helpText="Send to an on-call schedule"
                  dataTestId="oncall-schedule"
                  {...getRadioProps({ value: 'OnCallSchedule' })}
                />
                <FormikRadio
                  label="User"
                  dataTestId="user"
                  helpText="Send to an individual user"
                  {...getRadioProps({ value: 'User' })}
                />
              </Flex>
            )}
          </FormikRadioGroupV2>
        </Flex>
        {formik.values.target_type === 'EscalationPolicy' && (
          <Flex
            direction="column"
            w="100%"
            bg="white"
            px="5"
            pt="4"
            pb="6"
            borderRadius="lg"
            border="1px solid"
            borderColor="gray.100">
            {formik.errors.escalationpolicy_target && (
              <Text color="red.500" fontSize="sm" mt="1">
                {formik.errors.escalationpolicy_target}
              </Text>
            )}
            {escalationPolicies.length === 0 && (
              <>
                <Text p="3" color="gray.400">
                  No escalation policies created for this team.
                </Text>
                <Button
                  as={OrgRouteLink}
                  to={`/settings/teams/${id}/escalation_policies/new`}
                >
                  Create an escalation policy
                </Button>
              </>
            )}
            {escalationPolicies.length > 0 && (
              <>
                <Text as="h4" fontWeight="500" mb="3">
                  Choose an escalation policy to notify
                </Text>
                <FormV2.Dropdown
                  name="escalationpolicy_target"
                  placeholder="Select an escalation policy"
                  options={escalationPolicies.map((policy) => ({
                    label: policy.default
                      ? `${policy.name} (Default)`
                      : policy.name,
                    value: policy.id,
                    type: 'EscalationPolicy',
                  }))}
                />
              </>
            )}
          </Flex>
        )}
        {formik.values.target_type === 'OnCallSchedule' && (
          <Flex
            direction="column"
            w="100%"
            bg="white"
            px="5"
            pt="4"
            pb="6"
            borderRadius="lg"
            border="1px solid"
            borderColor="gray.100">
            {formik.errors.oncallschedule_target && (
              <Text color="red.500" fontSize="sm" mt="1">
                {formik.errors.oncallschedule_target}
              </Text>
            )}
            {schedules.length === 0 && (
              <>
                <Text p="3" color="gray.400">
                  No schedules created for this team.
                </Text>
                <Button
                  as={OrgRouteLink}
                  to={`/settings/teams/${id}/schedules/new`}
                >
                  Create an oncall schedule
                </Button>
              </>
            )}
            {schedules.length > 0 && (
              <>
                <Text as="h4" fontWeight="500" mb="3">
                  Choose an on-call schedule to notify
                </Text>
                <FormV2.Dropdown
                  name="oncallschedule_target"
                  placeholder="Select on-call schedule"
                  options={schedules.map((schedule) => ({
                    label: schedule.name,
                    value: schedule.id,
                    type: 'OnCallSchedule',
                  }))}
                />
              </>
            )}
          </Flex>
        )}
        {formik.values.target_type === 'User' && (
          <Flex
            direction="column"
            w="100%"
            bg="white"
            px="5"
            pt="4"
            pb="6"
            borderRadius="lg"
            border="1px solid"
            borderColor="gray.100">
            {formik.errors.user_target && (
              <Text color="red.500" fontSize="sm" mt="1">
                {formik.errors.user_target}
              </Text>
            )}
            {userMembers.length === 0 && (
              <>
                <Text p="3" color="gray.400">
                  No users have been added to this team. Targets are only valid
                  as FireHydrant users, not references to third party schedules.
                </Text>
                <TeamAddMembersForm />
              </>
            )}
            <Text as="h4" fontWeight="500" mb="3">
              Choose a user to notify
            </Text>
            {userMembers.length > 0 && (
              <FormV2.Dropdown
                name="user_target"
                placeholder="Select a user"
                options={userMembers.map((user) => ({
                  label: user.user.name,
                  value: user.user.id,
                  type: 'User',
                }))}
              />
            )}
          </Flex>
        )}
      </Flex>
    </>
  );
};

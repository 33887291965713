import React, { useContext, useEffect, useState } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

const StepperContext = React.createContext();

function Stepper({ children, defaultActiveIndex = 0, setStep = () => {} }) {
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);

  const handleStep = (index) => {
    setStep(index);
    setActiveIndex(index);
  };

  useEffect(() => {
    handleStep(defaultActiveIndex);
  }, [defaultActiveIndex]);

  return (
    <StepperContext.Provider
      value={{ activeIndex, setActiveIndex: handleStep }}
    >
      {children}
    </StepperContext.Provider>
  );
}

const Steps = ({ children }) => {
  const { activeIndex, setActiveIndex } = useContext(StepperContext);
  return (
    <Flex gap="3" width="100%" flexDirection={{ base: 'column', md: 'row' }}>
      {React.Children.map(children, (child, index) => {
        if (!child) {
          return null;
        }

        return (
          <Box
            flex="1"
            as="button"
            pt={{ md: 2 }}
            pl={{ base: 2, md: 0 }}
            minHeight={{ base: '60px', md: 0 }}
            onClick={() => setActiveIndex(index)}
            borderTop={{ md: '4px solid' }}
            borderTopColor={{
              md: activeIndex >= index ? 'primary.600' : 'grey.200',
            }}
            borderLeft={{ base: '4px solid', md: 'none' }}
            borderLeftColor={{
              base: activeIndex >= index ? 'primary.600' : 'grey.200',
              md: 'none',
            }}
            textAlign="left"
          >
            <Text mb="0" fontWeight="500" color="grey.900">
              Step {index + 1}
            </Text>
            {child}
          </Box>
        );
      })}
    </Flex>
  );
};

const Step = ({ children }) => {
  return (
    <Box>
      <Text mb="0" color="grey.600">
        {children}
      </Text>
    </Box>
  );
};

const StepPanels = ({ children, ...props }) => {
  const context = useContext(StepperContext);

  return (
    <Box {...props}>
      {React.Children.map(children, (child, index) => {
        if (context.activeIndex === index) {
          return child;
        } else {
          return null;
        }
      })}
    </Box>
  );
};

const StepPanel = ({ children }) => {
  return <Box>{children}</Box>;
};

export { Stepper, Steps, Step, StepPanels, StepPanel };

import { useMemo } from 'react';

import { trackEvent } from 'helpers/pawPrints';
import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import internalAPI from '../helpers/internalAPI';

export const ONBOARDING_TASKS = 'onboardingTasks';

export const useOnboardingTasks = (params = {}, options = {}) => {
  const query = useQuery(
    [ONBOARDING_TASKS, params],
    () => internalAPI.get('/internal_api/v1/onboarding/tasks'),
    options,
  );

  return {
    ...query,
    data: query?.data?.data,
  };
};

export const useCompleteOnboardingTasks = (params) =>
  useMutation(
    (params) =>
      internalAPI.post(
        `/internal_api/v1/onboarding/tasks/${params.slug}/complete`,
        params,
      ),
    params,
  );

const dismissOnboardingTasks = (params) =>
  internalAPI.post('/internal_api/v1/onboarding/dismiss', params);

export const useDismissOnboardingTasks = (params) =>
  useMutation(dismissOnboardingTasks, params);

export const useOnboardingStep = (slug, params = {}) => {
  const searchParams = useSearchParams()[0];
  const isSignalsOnboarding = searchParams.get('onboarding') === 'signals';
  const { data: onboardingTasks, isLoading } = useOnboardingTasks(
    {},
    {
      enabled: searchParams.get('onboarding') === 'signals',
    },
  );
  const onboardingTask = useMemo(() => {
    return onboardingTasks?.data?.tasks?.find((task) => task.id === slug);
  }, [onboardingTasks, slug]);
  const { mutate: completeOnboarding } = useCompleteOnboardingTasks(params);
  const completeOnboardingStep = () => {
    completeOnboarding({ slug });
    trackEvent({
      name: 'Signals',
      params: {
        page: 'User successfully complete step in setup list',
        event: slug,
      },
    });
  };
  return {
    isSignalsOnboarding,
    completeOnboardingStep,
    onboardingTask,
    isLoading,
    isStepComplete: onboardingTask?.complete,
  };
};

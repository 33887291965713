import React, { useEffect, useState } from 'react';

import { Tab, TabList, Tabs } from '@chakra-ui/react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import OrgRouteLink from 'components/common/OrgRouteLink';

export const AccountTabs = ({ selectedTabIndex }) => {
  const [tabIndex, setTabIndex] = useState(selectedTabIndex);
  const { signals } = useFlags();

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  useEffect(() => {
    handleTabsChange(selectedTabIndex);
  }, [selectedTabIndex]);

  return (
    <>
      <Tabs
        index={tabIndex}
        onChange={handleTabsChange}
        maxW="900px"
        overflow="auto"
      >
        <TabList>
          <Tab as={OrgRouteLink} to="edit">
            Profile
          </Tab>
          {signals && (
            <Tab as={OrgRouteLink} to="notification_preferences">
              Signals Notifications
            </Tab>
          )}
          <Tab as={OrgRouteLink} to="password_security">
            Password & Security
          </Tab>
          <Tab as={OrgRouteLink} to="organization_settings">
            User Settings
          </Tab>
        </TabList>
      </Tabs>
    </>
  );
};

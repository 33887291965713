import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';

const ToolbarPopover = ({ trigger, children }) => {
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Box>{trigger}</Box>
      </PopoverTrigger>
      <PopoverContent width="max-content" border="none">
        <PopoverArrow backgroundColor="grey.1100" boxShadow="none" />
        <PopoverBody
          backgroundColor="grey.1100"
          color="grey.900"
          p={3}
          borderRadius="md"
        >
          {children}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ToolbarPopover;

import { ENTITLEMENT_TITLE } from '../constants';

export const formatTier = (tier) => tier[0].toUpperCase() + tier.slice(1);

export const formatTierWithArticle = (tier) => {
  const name = formatTier(tier);
  // indefinite articles are trickier than this, but this works for our needs
  if (['A', 'E', 'I', 'O', 'U'].some((vowel) => name.startsWith(vowel))) {
    return `an ${name}`;
  }
  return `a ${name}`;
};

export const formatTitle = (entitlement, quotaLimit) =>
  ENTITLEMENT_TITLE[entitlement] + (quotaLimit > 1 ? 's' : '');

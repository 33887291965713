import React from 'react';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  CloseButton,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';

const colorScheme = {
  info: 'blue.400',
  success: 'green.400',
  warning: 'yellow.200',
  error: 'red.400',
};
const AlertBar = ({
  children,
  disableClose,
  variant = 'info',
  onClose,
  ...props
}) => {
  const color = colorScheme[variant] || colorScheme.info;
  const { isOpen, onClose: onCloseClick } = useDisclosure({
    defaultIsOpen: true,
  });
  if (!isOpen) return null;
  return (
    <Alert
      bg="white"
      borderRadius="md"
      status={variant}
      border="1px solid"
      borderLeftWidth="4px"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        borderColor: color,
      }}
      {...props}
    >
      <Flex w="100%" justifyContent="space-between">
        <Flex justifyContent="flex-start" alignItems="center">
          <AlertIcon
            status={variant}
            color={color}
            mr="2"
            w="16px"
            h="16px"
            position="relative"
            top="-1px"
          />
          <AlertDescription
            lineHeight="1.0"
            mb="0"
            color="gray.700"
            fontWeight="400"
            mbr="1"
          >
            {children}
          </AlertDescription>
        </Flex>
        {!disableClose && (
          <CloseButton
            alignSelf="flex-start"
            position="relative"
            right={-2}
            top={-2}
            onClick={() => {
              if (onClose) {
                onClose();
              }
              onCloseClick();
            }}
          />
        )}
      </Flex>
    </Alert>
  );
};

export default AlertBar;

export const timeOptions = [
  { label: 'Immediately', value: 'PT0S' },
  { label: '1 min', value: 'PT1M' },
  { label: '2 mins', value: 'PT2M' },
  { label: '5 mins', value: 'PT5M' },
  { label: '10 mins', value: 'PT10M' },
  { label: '15 mins', value: 'PT15M' },
  { label: '30 mins', value: 'PT30M' },
  { label: '1 hour', value: 'PT1H' },
];

export const generateInitialValues = (data) => {
  const initialFormValues = {
    notifications: [
      {
        notification_type: '',
        delay: '',
      },
    ],
    hours_setting: 'standard',
    workdays: { label: 'Weekdays', value: 'weekdays' },
    start_time: '09:00',
    end_time: '17:00',
    timezone: 'UTC',
    monday: {
      start_time: '09:00',
      end_time: '17:00',
      included: true,
    },
    tuesday: {
      start_time: '09:00',
      end_time: '17:00',
      included: true,
    },
    wednesday: {
      start_time: '09:00',
      end_time: '17:00',
      included: true,
    },
    thursday: {
      start_time: '09:00',
      end_time: '17:00',
      included: true,
    },
    friday: {
      start_time: '09:00',
      end_time: '17:00',
      included: true,
    },
    saturday: {
      start_time: '09:00',
      end_time: '17:00',
      included: false,
    },
    sunday: {
      start_time: '09:00',
      end_time: '17:00',
      included: false,
    },
  };

  if (data) {
    initialFormValues.notifications = data?.map((item) => {
      const notification = {
        label: item.destination.label,
        value: item.destination.value,
        notification_type: item.notification_type,
      };
      const delay = timeOptions.find((option) => option.value === item.delay);

      return {
        id: item.id,
        notification_type: notification,
        delay: delay,
      };
    });
  }

  return initialFormValues;
};

export const generateNotificationOptions = (destinations) => {
  if (!destinations) {
    return [];
  }

  const notificationOptions = [
    {
      label: 'Email',
      options: destinations.filter(
        (destination) => destination.type === 'email',
      ),
    },
    {
      label: 'Slack',
      options: destinations.filter(
        (destination) => destination.type === 'slack',
      ),
    },
    {
      label: 'SMS',
      options: destinations
        .filter((destination) => destination.type === 'phone')
        .map((phone) => ({ ...phone, type: 'sms' })),
    },
    {
      label: 'Voice',
      options: destinations
        .filter((destination) => destination.type === 'phone')
        .map((phone) => ({ ...phone, type: 'voice' })),
    },
    {
      label: 'WhatsApp',
      options: destinations
        .filter((destination) => destination.type === 'phone')
        .map((phone) => ({ ...phone, type: 'whats_app' })),
    },
    {
      label: 'Push Notification',
      options: destinations.filter(
        (destination) =>
          destination.type === 'apns' || destination.type === 'fcm',
      ),
    },
  ];

  return notificationOptions.filter((option) => option.options.length > 0);
};

import React from 'react';

import { Box, Text } from '@chakra-ui/react';
import loadable from '@loadable/component';
import { useUserContext } from 'contexts/UserContext/UserContextProvider';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { AccountTabs } from './AccountTabs';

const Account = loadable(() => import('components/account_v2/Account'));
const PasswordSecurityPage = loadable(() =>
  import('components/account_v2/PasswordSecurityPage'),
);
const OrganizationSettingsPage = loadable(() =>
  import('components/account_v2/OrganizationSettingsPage'),
);
const SignalsNotifications = loadable(() =>
  import('components/account_v2/SignalsNotifications'),
);

export const AccountRoutes = () => {
  const { currentUser } = useUserContext();
  const { pathname } = useLocation();
  const { signals } = useFlags();

  const layoutRoutes = [
    '/account/edit',
    '/account/notification_preferences',
    '/account/password_security',
    '/account/organization_settings',
  ];

  if (!signals) {
    layoutRoutes.splice(1, 1);
  }

  const selectedTabIndex = layoutRoutes.findIndex((tab) =>
    pathname.includes(tab),
  );

  return (
    <Box>
      <Text as="h2" color="grey.800" size="3" mb="4">
        Welcome, {currentUser?.name}
      </Text>
      <AccountTabs selectedTabIndex={selectedTabIndex} />
      <Routes maxW="1000px">
        <Route element={<Account />} path="/edit" />
        <Route
          element={<SignalsNotifications />}
          path="/notification_preferences"
        />
        <Route element={<PasswordSecurityPage />} path="/password_security" />
        <Route
          element={<OrganizationSettingsPage />}
          path="/organization_settings"
        />
        <Route path="*" element={<Navigate replace to="/edit" />} />
      </Routes>
    </Box>
  );
};

export default AccountRoutes;

import React from 'react';

import { Flex, forwardRef } from '@chakra-ui/react';
import { useUserContext } from 'contexts/UserContext/UserContextProvider';

import IdItem from './IdItem';
import ToolbarPopover from './ToolbarPopover';
import ToolbarText from './ToolbarText';

const EnvironmentBadge = () => {
  const { currentOrganization, account, currentUser } = useUserContext();
  const defaults = {
    'local.firehydrant.io': {
      name: 'Development',
      color: 'purple.600',
    },
    'app.staging.firehydrant.io': {
      name: 'Staging',
      color: 'blue.600',
    },
    'app.firehydrant.io': {
      name: 'Production',
      color: 'red.600',
    },
    default: {
      name: 'Unknown',
      color: '#grey.600',
    },
  };

  const organization = currentOrganization.node || {
    id: 'unknown',
    name: 'unknown',
  };

  const environment = defaults[window.location.host] || defaults['default'];

  const name = environment.name;
  const color = environment.color;

  const Badge = forwardRef((props, ref) => {
    return (
      <Flex
        gap={2}
        alignItems="center"
        px={2}
        py={1}
        mr={1}
        borderRadius="md"
        backgroundColor={color}
        ref={ref}
        cursor="pointer"
      >
        <ToolbarText fontWeight="bold" color="white">
          {name}
        </ToolbarText>
      </Flex>
    );
  });

  return (
    <ToolbarPopover trigger={<Badge />}>
      <Flex direction="column" gap={4}>
        <IdItem title="Account" name={account.name} id={account.id} />
        <IdItem
          title="Organization"
          name={organization.name}
          id={organization.id}
        />
        <IdItem title="User" name={currentUser.name} id={currentUser.id} />
      </Flex>
    </ToolbarPopover>
  );
};

export default EnvironmentBadge;

import React from 'react';

import { Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const NotificationLabel = ({ notification }) => {
  let text;

  switch (notification.notification_type.notification_type) {
    case 'email':
      text = `Email`;
      break;
    case 'slack':
      text = `Slack`;
      break;
    case 'sms':
      text = `SMS`;
      break;
    case 'voice':
      text = `Voice`;
      break;
    case 'whats_app':
      text = `WhatsApp`;
      break;
    case 'apns':
      text = `iOS`;
      break;
    case 'fcm':
      text = `Android`;
      break;
    default:
      text = null;
  }

  return (
    <Text mb="0" display="inline-block" color="gray.900" fontSize="6">
      {text}
    </Text>
  );
};

NotificationLabel.propTypes = {
  notification: PropTypes.shape({
    notification_type: PropTypes.shape({
      notification_type: PropTypes.string,
    }),
  }).isRequired,
};

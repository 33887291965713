import React from 'react';

import { Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import { Link } from '@firehydrant/design-system';
import { useCookies } from 'react-cookie';

const DeployPreview = () => {
  const [cookies] = useCookies(['laddertruck']);
  const cookieKey = 'branch_deploy_sha';
  const sha = cookies[cookieKey];

  if (!sha) return false;

  const handlePreview = () => {
    const url = new URL(location.href);
    url.searchParams.set('reset_branch_deploy_sha', 'true');
    url.searchParams.delete('branch_deploy_sha');
    location.href = url.href;
  };

  return (
    <Tag ml="2" variant="outline" textTransform="none" px="2" py="1">
      <TagLabel>
        <Link
          href={`https://github.com/firehydrant/laddertruck/commits/${sha}`}
          fontSize="auto"
          color="grey.600"
        >
          Preview branch
        </Link>
      </TagLabel>
      <TagCloseButton onClick={handlePreview} />
    </Tag>
  );
};

export default DeployPreview;

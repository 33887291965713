import React from 'react';

import nodeEmoji from 'node-emoji';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';

import { StyledMarkdown } from './StyledMarkdown';
import { cleanUpCodeBlocks } from './markdown_helper';

export function Markdown({
  source,
  fontSize = 6,
  disallowedElements,
  color = 'gray-800',
}) {
  let filteredSource =
    source
      ?.replace(/:skin-tone-\d:/gi, '')
      ?.replace(/:\w+:/gi, (emoji) => nodeEmoji.get(emoji)) || '';

  filteredSource = cleanUpCodeBlocks(filteredSource);

  return (
    <StyledMarkdown fontSize={fontSize} color={color}>
      <ReactMarkdown
        disallowedElements={
          disallowedElements
            ? disallowedElements
            : [
                'table',
                'caption',
                'colgroup',
                'thead',
                'tfoot',
                'tbody',
                'tr',
                'td',
                'th',
              ]
        }
        remarkPlugins={[breaks, remarkGfm]}
        skipHtml
        unwrapDisallowed
        linkTarget="_blank"
      >
        {filteredSource}
      </ReactMarkdown>
    </StyledMarkdown>
  );
}

import { createMutationHook, useApiQuery } from 'queries/common';

import firehydrantAPI from '../../helpers/firehydrantAPI';

export const usePhones = (params) => {
  const requestParams = { params: { per_page: 20, ...params } };

  const query = useApiQuery(['phones', 'list', requestParams], () =>
    firehydrantAPI.get('/phones', requestParams),
  );

  return {
    ...query,
    data: query.data?.data?.data ?? [],
    pagination: query.data?.data?.pagination ?? {},
  };
};

const createPhone = (body) => firehydrantAPI.post('/phones', body);
export const useCreatePhone = createMutationHook(createPhone);

const deletePhone = (body) => firehydrantAPI.delete(`/phones/${body.id}`, body);
export const useDeletePhone = createMutationHook(deletePhone);

const verifyPhone = (body) =>
  firehydrantAPI.put(`/phones/${body.id}/verify`, body);
export const useVerifyPhone = createMutationHook(verifyPhone);

const resendPhone = (body) =>
  firehydrantAPI.put(`/phones/${body.id}/resend`, body);
export const useResendPhone = createMutationHook(resendPhone);

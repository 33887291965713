import React from 'react';

import { components } from 'react-select';

import { Icon } from '../icons';

export const MultiValueRemove = (props) => (
  <components.MultiValueRemove {...props}>
    <Icon height="14px" width="14px" name="close" />
  </components.MultiValueRemove>
);

import { useCallback, useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

// Note this component should not live for a long period of time. We are using an UNSAVE api
// in order to get us by while we move to the new `createBrowserRouter` api in React Router v6.
// Then we will have access to the built in hooks for managing blocking and prompting.

export default function Prompt({
  message = 'Are you sure you want to navigate away? You’ll lose your changes.',
  when,
}) {
  usePrompt(message, when);

  return null;
}

Prompt.propTypes = {
  message: PropTypes.string,
  when: PropTypes.bool.isRequired,
};

function useConfirmExit(confirmExit, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    // To prevent a horrible developer experience with file change reloading
    // within development mode we turn this feature off. So you can add a
    // Prompt component without the constant confirm dialog popping up.
    if (!when || process.env.NODE_ENV === 'development') {
      return;
    }

    const push = navigator.push;

    navigator.push = (...args) => {
      const result = confirmExit();
      if (result !== false) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
}

function usePrompt(message, when = true) {
  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return message;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when]);

  const confirmExit = useCallback(() => {
    const confirm = window.confirm(message);
    return confirm;
  }, [message]);
  useConfirmExit(confirmExit, when);
}

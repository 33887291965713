import React from 'react';

import { Button, ButtonGroup, Flex, Text } from '@chakra-ui/react';
import { Icon } from '@firehydrant/design-system';
import { getAPIError } from 'helpers/firehydrantAPI';
import PropTypes from 'prop-types';
import {
  useCreatePhone,
  useResendPhone,
  useVerifyPhone,
} from 'queries/notificationSettings/phones';
import * as Yup from 'yup';

import FormV2 from 'components/common/Form/FormV2/FormV2';
import FormikPhoneInput, {
  isPossiblePhoneNumber,
} from 'components/common/Form/FormikPhoneInput';
import Modal from 'components/common/Modals/Modal';
import { useToastContext } from 'components/common/ToastBannerProvider/ToastBannerProvider';
import {
  Step,
  StepPanel,
  StepPanels,
  Stepper,
  Steps,
} from 'components/integrations/components/shared/Stepper';

export const PhoneVerificationModal = ({
  verifyNumber,
  setVerifyNumber,
  setReverify,
  reverify,
  refetch,
}) => {
  const { addToast } = useToastContext();
  const [step, setStep] = React.useState(0);
  const { mutate: createPhone } = useCreatePhone({
    onError: (err) => {
      const error = getAPIError(err);
      addToast({ message: error, variant: 'alert' });
    },
  });

  const { mutate: verifyPhone } = useVerifyPhone({
    onError: (err) => {
      const error = getAPIError(err);
      addToast({ message: error, variant: 'alert' });
    },
  });

  const { mutate: resendPhone } = useResendPhone({
    onError: (err) => {
      const error = getAPIError(err);
      addToast({ message: error, variant: 'alert' });
    },
  });

  const validationSchema = Yup.object().shape({
    number: Yup.string()
      .test('phone', 'Phone number is not valid', (value) =>
        isPossiblePhoneNumber(value || ''),
      )
      .required('Phone number is required'),
  });

  const validationSchema2 = Yup.object().shape({
    code: Yup.string().required('Required'),
  });

  const verificationMethodOptions = [
    { label: 'SMS', value: 'sms' },
    { label: 'Call', value: 'voice' },
    { label: 'WhatsApp', value: 'whats_app' },
  ];

  const initialNumber = reverify ? verifyNumber.number : '+1 ';

  return (
    <Modal
      onClose={() => {
        setVerifyNumber(null);
        setReverify(false);
        setStep(0);
      }}
      isOpen={!!verifyNumber}
      headerText={reverify ? 'Verify phone number' : 'Add phone number'}
      showFooter={false}
      modalWidth="xl"
      modalBody={
        <>
          <Stepper defaultActiveIndex={step} setStep={setStep} stepCount="2">
            <Steps>
              <Step>Choose verification method</Step>
              <Step>Enter code</Step>
            </Steps>
            <StepPanels mt="4">
              <StepPanel>
                <FormV2
                  validationSchema={validationSchema}
                  initialValues={{
                    number: initialNumber,
                    verification_method: verificationMethodOptions[0],
                  }}
                  onSubmit={(values, formik) => {
                    if (reverify && values.number === verifyNumber.number) {
                      resendPhone(
                        {
                          id: verifyNumber.id,
                          verification_method:
                            values.verification_method?.value,
                        },
                        {
                          onSuccess: () => {
                            refetch();
                            addToast({
                              message: `Verification code has been resent to ${verifyNumber.number}.`,
                              variant: 'success',
                            });
                            setStep(1);
                          },
                        },
                      );
                      return;
                    }
                    createPhone(
                      {
                        number: values.number,
                        verification_method: values.verification_method?.value,
                      },
                      {
                        onSuccess: (result) => {
                          refetch();
                          setReverify(true);
                          formik.resetForm({
                            values: {
                              number: '',
                              verification_method: verificationMethodOptions[0],
                            },
                          });
                          setVerifyNumber(result.data);
                          setStep(1);
                        },
                      },
                    );
                  }}
                >
                  <Flex gap="2" flexDir="column">
                    <FormikPhoneInput
                      placeholder="+1 999-999-9999"
                      label="Phone number"
                      name="number"
                      mb="0"
                      data-testid="number"
                      isDisabled={reverify}
                    />
                    <FormV2.Dropdown
                      label="Verification method"
                      name="verification_method"
                      options={verificationMethodOptions}
                    />
                  </Flex>
                  <Flex>
                    <Button
                      type="submit"
                      variant="outline"
                      rightIcon={<Icon name="chevronRight" boxSize="5" />}
                    >
                      Send Verification
                    </Button>
                  </Flex>
                </FormV2>
              </StepPanel>
              <StepPanel>
                <FormV2
                  initialValues={{ code: '' }}
                  validationSchema={validationSchema2}
                  onSubmit={(values, formik) => {
                    verifyPhone(
                      { id: verifyNumber.id, code: values.code },
                      {
                        onSuccess: () => {
                          refetch();
                          formik.resetForm({ values: { code: '' } });
                          addToast({
                            message: `Phone number ${verifyNumber.number} has been verified for your account.`,
                            variant: 'success',
                          });
                          setVerifyNumber(null);
                          setReverify(false);
                          setStep(0);
                        },
                      },
                    );
                  }}
                >
                  <Text mb="2">
                    A verification code has been sent to{' '}
                    <b>{verifyNumber?.number}</b>.
                  </Text>
                  <FormV2.Input
                    label="Enter code"
                    name="code"
                    data-testid="code"
                    type="text"
                    placeholder="*******"
                  />

                  <Flex justifyContent="space-between" mt="2">
                    <Button
                      variant="link"
                      leftIcon={<Icon name="chevronLeft" boxSize="5" />}
                      onClick={() => {
                        setStep(0);
                      }}
                    >
                      Back
                    </Button>
                    <ButtonGroup>
                      <Button
                        variant="outline"
                        onClick={() => {
                          setVerifyNumber(null);
                          setReverify(false);
                          setStep(0);
                        }}
                      >
                        Verify later
                      </Button>
                      <Button type="submit">Verify</Button>
                    </ButtonGroup>
                  </Flex>
                </FormV2>
              </StepPanel>
            </StepPanels>
          </Stepper>
        </>
      }
      openButtonText="Save"
      openButtonVariant="solid"
    />
  );
};

PhoneVerificationModal.propTypes = {
  verifyNumber: PropTypes.shape({
    id: PropTypes.string,
    number: PropTypes.string,
  }),
  setVerifyNumber: PropTypes.func.isRequired,
  setReverify: PropTypes.func.isRequired,
  reverify: PropTypes.bool.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default PhoneVerificationModal;

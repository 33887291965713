import React, { useState } from 'react';

import { Alert, Box, Button, Flex, Text } from '@chakra-ui/react';
import { Link } from '@firehydrant/design-system';
import { Form, Formik } from 'formik';
import { useUpdateCurrentUserPassword } from 'queries/users';
import * as Yup from 'yup';

import FormikTextInput from 'components/common/Form/FormikTextInput';
import { useToastContext } from 'components/common/ToastBannerProvider/ToastBannerProvider';

const formatPasswordErrorMessage = (responseError) => {
  let formattedErrors = [];

  if (typeof responseError === 'string') {
    formattedErrors.push(responseError);
  }

  // Password error
  if (responseError?.password) {
    formattedErrors.push(`Password ${responseError?.password.join(', ')}`);
  }

  // Password Confirmation error
  if (responseError?.password_confirmation) {
    formattedErrors.push(
      `Confirmation password ${responseError?.password_confirmation.join(
        ', ',
      )}`,
    );
  }

  const errorMessage =
    formattedErrors.length > 0
      ? formattedErrors.join('. ')
      : 'An error occurred updating your account';

  return errorMessage;
};

const UpdatePasswordForm = () => {
  const { addToast } = useToastContext();
  const [responseErrors, setResponseErrors] = useState(false);

  const onSuccess = () => {
    addToast({
      variant: 'success',
      message: 'Your password has been updated.',
    });
  };

  const onError = (error) => {
    const responseError = error?.response?.data?.error;
    const formattedErrorMessage = formatPasswordErrorMessage(responseError);

    setResponseErrors(formattedErrorMessage);
  };

  const { mutate: mutateUpdateUserPassword } = useUpdateCurrentUserPassword({
    onError,
    onSuccess,
  });

  const initialValues = {
    current_password: '',
    password: '',
    password_confirmation: '',
  };

  const validationSchema = Yup.object().shape({
    current_password: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
    password_confirmation: Yup.string().required('Required'),
  });

  const handleSubmit = (values) => {
    setResponseErrors(false);
    mutateUpdateUserPassword(values);
  };

  return (
    <Flex flexDirection="column" maxWidth="600px">
      <Box mb={4}>
        <Link href="/forgot_passwords/new">Forgot password?</Link>
        <Text as="h3" color="grey.900" size={4} mt={2}>
          Change password
        </Text>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <>
            {responseErrors && (
              <Alert variant="alert" mb="2">
                {responseErrors}
              </Alert>
            )}
            <Form>
              <FormikTextInput
                autoComplete="current-password"
                name="current_password"
                label="Current password"
                type="password"
              />
              <FormikTextInput
                autoComplete="off"
                name="password"
                type="password"
                label="New password"
              />
              <FormikTextInput
                autoComplete="off"
                label="Confirm new password"
                name="password_confirmation"
                type="password"
                confirm_password="password"
              />
              <Button type="submit">Update password</Button>
            </Form>
          </>
        </Formik>
      </Box>
    </Flex>
  );
};

export default UpdatePasswordForm;

import firehydrantAPI from 'helpers/firehydrantAPI';
import find from 'lodash/find';
import { useQuery } from 'react-query';

export const ENTITLEMENTS_LIST = 'ENTITLEMENT_LIST';

export const useEntitlement = (params = {}, enabled = true) => {
  const query = useQuery(
    [ENTITLEMENTS_LIST, params],
    () => firehydrantAPI.get(`entitlements`, params),
    { enabled },
  );

  return {
    ...query,
    data: find(query?.data?.data?.data, { slug: params.params.name }) || {},
  };
};

export const useQuotas = (params) => {
  const requestParams = { params: { type: 'quota', ...params } };
  const query = useQuery(['/entitlements', requestParams], () =>
    firehydrantAPI.get(`entitlements`, requestParams),
  );

  return {
    ...query,
    data: query?.data?.data?.data || {},
  };
};

import React from 'react';

import {
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Skeleton,
} from '@chakra-ui/react';
import { useTeams } from 'queries/teams';

import OrgRouteLink from 'components/common/OrgRouteLink';

import { MobileSubMenuItem } from '../MobileMenuItem';

export default function TeamsFlyoutMenu({ onClose }) {
  const { data, isLoading } = useTeams({ my_teams: true, lite: true });

  return (
    <MenuList zIndex="2000">
      <MenuGroup title="My Teams" fontSize="12px" py="2" color="gray.700">
        {isLoading && (
          <>
            <MenuItem data-testid="loading">
              <Skeleton height="14px" width="100%"></Skeleton>
            </MenuItem>
            <MenuItem>
              <Skeleton height="14px" width="100%"></Skeleton>
            </MenuItem>
          </>
        )}
        {!isLoading && data.length === 0 && (
          <MenuItem isDisabled>You are not a member of any teams</MenuItem>
        )}
        {!isLoading &&
          data.map((team) => (
            <MenuItem
              onClick={onClose}
              key={team.id}
              as={OrgRouteLink}
              to={`/settings/teams/${team.id}/overview`}
            >
              {team.name}
            </MenuItem>
          ))}
      </MenuGroup>
      <MenuDivider />
      <MenuItem as={OrgRouteLink} to="/settings/teams" onClick={onClose}>
        View all Teams
      </MenuItem>
    </MenuList>
  );
}

export function TeamsMobileMenu() {
  const { data, isLoading } = useTeams({ my_teams: true, lite: true });

  const teams = data.map((team) => ({
    id: team.id,
    name: team.name,
    href: `/settings/teams/${team.id}/overview`,
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  }));

  const allTeam = {
    id: 'all',
    name: 'View all Teams',
    href: `/settings/teams`,
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  };

  return [...teams, allTeam]?.map((subItem) => {
    return <MobileSubMenuItem subItem={subItem} key={subItem.name} />;
  });
}

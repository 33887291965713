import React from 'react';

import {
  Box,
  Flex,
  Icon,
  MenuItem,
  Skeleton,
  Tag,
  Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FiCopy, FiEdit, FiTrash } from 'react-icons/fi';
import { HiOutlineClock, HiOutlineLightningBolt } from 'react-icons/hi';

import ActionDropdown from 'components/common/ActionDropdown';

import { NotificationIcon } from './NotificationIcon';
import { NotificationLabel } from './SignalsNotificationLabel';
import { NotificationMessage } from './SignalsNotificationMessage';

export const NotificationRow = ({
  index,
  item,
  toggleEditState,
  isLoading,
  deletePreference,
  duplicatePreference,
  arrayHelpers,
}) => {
  if (isLoading || !item.notification_type?.notification_type) {
    return <Skeleton height="20px" width="100%" />;
  }

  return (
    <Flex
      width="100%"
      alignItems="center"
      data-testid="edit-button"
      justifyContent={{ base: 'space-between', md: 'space-around' }}
    >
      <Flex minWidth="20%" alignItems="center" gap="2">
        <NotificationIcon type={item.notification_type?.notification_type} />
        <NotificationLabel notification={item} />
      </Flex>
      <Box width="40%" display={{ base: 'none', md: 'block' }}>
        <NotificationMessage notification={item} />
      </Box>
      <Box minWidth="25%">
        <Tag variant="roundedOutline">
          {item.delay?.value === 'PT0S' ? (
            <Icon as={HiOutlineLightningBolt} />
          ) : (
            <Icon as={HiOutlineClock} />
          )}
          <Text mb="0" pl="1" fontSize="7" fontWeight="500" color="gray.700">
            {item.delay?.label}
          </Text>
        </Tag>
      </Box>
      <Flex justifyContent="flex-end">
        <ActionDropdown inPortal>
          <MenuItem onClick={() => toggleEditState(index)} data-testid="edit">
            <Flex alignItems="center" gap="2">
              <Icon as={FiEdit} mr="2" />
              Edit
            </Flex>
          </MenuItem>
          {item.delay?.value !== 'PT1H' && (
            <MenuItem
              onClick={() => duplicatePreference(item)}
              data-testid="duplicate"
            >
              <Flex alignItems="center" gap="2">
                <Icon as={FiCopy} mr="2" />
                Duplicate
              </Flex>
            </MenuItem>
          )}

          <MenuItem
            onClick={() => deletePreference(item, index, arrayHelpers.remove)}
            data-testid="delete"
          >
            <Flex alignItems="center" gap="2">
              <Icon as={FiTrash} mr="2" />
              Delete
            </Flex>
          </MenuItem>
        </ActionDropdown>
      </Flex>
    </Flex>
  );
};

NotificationRow.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({
    notification_type: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        notification_type: PropTypes.string,
      }),
    ]),
    delay: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ]),
  }).isRequired,
  toggleEditState: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  formik: PropTypes.object.isRequired,
  deletePreference: PropTypes.func.isRequired,
  duplicatePreference: PropTypes.func.isRequired,
  arrayHelpers: PropTypes.shape({
    remove: PropTypes.func,
  }).isRequired,
};

import React from 'react';

import {
  Box,
  Button,
  Flex,
  Link,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Icon } from '@firehydrant/design-system';
import { useUserLinkedAccounts } from 'queries/userAccount';

import OrgRouteLink from 'components/common/OrgRouteLink';
import TooltipWrapper from 'components/common/TooltipWrapper';
import { orgIdFromLocation } from 'components/routes/multiOrgRoutesHelpers';

const LinkedAccounts = () => {
  const { data: linkedAccounts, isLoading } = useUserLinkedAccounts();

  const showLinkButton = (account) => {
    return (
      (!account.linked || account.integration_name === 'Jira Cloud') &&
      account.integration_name !== 'Slack'
    );
  };

  return (
    <Flex flexDirection="column">
      <Box pt={4} mb={4}>
        <Flex gap="2">
          <Text as="h3" color="grey.900" size={4}>
            Linked Accounts
          </Text>
          <TooltipWrapper
            placement="top"
            text="Use the below link actions to link accounts from integrations already configured. Jira issues? Visit the Jira Integration configuration to refresh the org-wide connection."
            targetId="linked-accounts"
          >
            <Icon
              name="info"
              id="linked-accounts"
              aria-label="linked-accounts tooltip"
              ml="1"
              mt="1"
            />
          </TooltipWrapper>
        </Flex>
        {linkedAccounts?.length < 1 && (
          <Text>
            You have no linked accounts.{' '}
            <Link as={OrgRouteLink} to="/organizations/integrations">
              Install an integration
            </Link>{' '}
            to get started.
          </Text>
        )}
        {linkedAccounts?.length > 0 && (
          <Table>
            <Thead>
              <Tr>
                <Th>Integration Name</Th>
                <Th>Linked?</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading
                ? linkedAccounts.map((account) => (
                    <Tr key={account.integration_name}>
                      <Td>{account.integration_name}</Td>
                      <Td>
                        {account.linked ? (
                          <Icon
                            name="check"
                            data-testid="checked"
                            color="green.400"
                          />
                        ) : (
                          <Icon
                            name="close"
                            data-testid="not-checked"
                            color="red.700"
                          />
                        )}
                      </Td>
                      <Td maxWidth="160px">
                        {showLinkButton(account) && (
                          <Button as="a" href={account.auth_url}>
                            Link
                          </Button>
                        )}
                        {account.integration_name === 'Slack' &&
                          !account.linked && (
                            <Button
                              as="a"
                              data-testid="link-slack-button"
                              href={`/org/${orgIdFromLocation()}/auth_kickoff/slack/for_installation`}
                            >
                              Link
                            </Button>
                          )}
                      </Td>
                    </Tr>
                  ))
                : Array.apply(null, Array(7)).map((row, i) => (
                    <Tr key={`row-${i}`}>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                    </Tr>
                  ))}
            </Tbody>
          </Table>
        )}
      </Box>
    </Flex>
  );
};

export default LinkedAccounts;

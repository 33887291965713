import React from 'react';

import { Box, Card, Flex, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import CodeBlock from 'components/common/CodeBlock';
import FormV2 from 'components/common/Form/FormV2/FormV2';
import IncidentTypesDropdown from 'components/common/Form/IncidentTypesDropdown';
import AlertNotificationPriorityDropdown from 'components/common/Form/AlertNotificationPriorityDropdown';

export const RuleDetailsPanel = () => {
  const formik = useFormikContext();

  let displayValue = '';
  let targetValue = '';

  if (formik?.values?.target_type === 'User') {
    displayValue = formik?.values?.user_target?.label;
    targetValue = 'User';
  } else if (formik?.values?.target_type === 'OnCallSchedule') {
    displayValue = formik?.values?.oncallschedule_target?.label;
    targetValue = 'OnCall Schedule';
  } else if (formik?.values?.target_type === 'EscalationPolicy') {
    displayValue = formik?.values?.escalationpolicy_target?.label;
    targetValue = 'Escalation Policy';
  }

  return (
    <Box>
      <Flex direction="column"
        w="100%"
        bg="white"
        px="5"
        pt="4"
        pb="6"
        borderRadius="lg"
        border="1px solid"
        borderColor="gray.100">
        <Text as="p" fontWeight="400" mb="4">
          Add a name, review, and save your rule.
        </Text>
        <Flex width="50%">
          <FormV2.Input
            name="name"
            label="Rule name"
            placeholder="Add rule name"
            data-1p-ignore
            isRequired
          />
        </Flex>
        <Flex mt="4" width="50%" sx={{ label: { fontWeight: 'bold' } }}>
          <IncidentTypesDropdown
            labelText="Set Incident Type for Incidents opened from these Alerts"
            autoComplete="off"
            placeholder="Select incident type"
            isClearable
          />
        </Flex>
        <Flex mt="4" width="50%" sx={{ label: { fontWeight: 'bold' } }} flexDirection="column">
          <Flex width="100%">
            <AlertNotificationPriorityDropdown
              labelText="Notification Priority"
              placeholder="Leave empty to skip overriding"
              name="notificationPriorityOverride"
              isClearable
            />
          </Flex>
          <Text fontWeight="300" fontSize={7}>
            Setting a notification priority on an alert rule will override the value regardless if it was set by someting else (For example: A Signal's annotations).
          </Text>
        </Flex>
        <Text fontSize="18px" fontWeight="500" mt="4">
          Review details
        </Text>
        <Card p="3" maxW="50%">
          <Flex flexDirection="column" mb="4">
            <Text as="label" fontWeight="600">
              Filter
            </Text>
            <CodeBlock>{formik?.values?.expression}</CodeBlock>
          </Flex>
          <Flex flexDirection="column">
            <Text as="label" fontWeight="600">
              {targetValue}
            </Text>
            <Text
              as="span"
              border="1px solid"
              borderColor="gray.200"
              backgroundColor="gray.50"
              display="inline-block"
              px="3"
              py="2"
              borderRadius="4"
            >
              {displayValue}
            </Text>
          </Flex>
        </Card>
      </Flex>
    </Box>
  );
};

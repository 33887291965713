import React from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  Modal as DSModal,
  Flex,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { IconButton } from '@firehydrant/design-system';
import PropTypes from 'prop-types';

import AutomationWarning from 'components/common/AutomationWarning';

const Modal = ({
  handleAction,
  headerText,
  isOpen,
  modalBody,
  onClose,
  children = null,
  confirmButtonText = 'Confirm',
  confirmButtonColorScheme = null,
  modalWidth = 'md',
  showCancel = true,
  showFooter = true,
  closeOnOverlayClick = true,
  isLoading = false,
  createdBy = {},
}) => {
  return (
    <DSModal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay />
      <ModalContent maxWidth={modalWidth} pb="4">
        <ModalHeader>
          <Flex alignItems="flex-start" justify="space-between">
            {/* Tiny margin to align with close icon. We don't align center because some titles are multiple lines */}
            <Box mt="2px">{headerText}</Box>{' '}
            <IconButton
              data-testid="modal-close-iconbutton"
              name="close"
              onClick={onClose}
              variant="ghost"
              size="sm"
            />
          </Flex>
        </ModalHeader>
        {(modalBody || children) && (
          <ModalBody>
            {modalBody && modalBody}
            {children && children}
          </ModalBody>
        )}
        {showFooter && (
          <ModalFooter pb="0">
            <Flex justifyContent="space-between" w="full">
              <AutomationWarning createdBy={createdBy} />
              <ButtonGroup>
                {showCancel && (
                  <Button variant="ghost" onClick={onClose}>
                    Cancel
                  </Button>
                )}
                <Button
                  colorScheme={confirmButtonColorScheme}
                  onClick={handleAction}
                  isLoading={isLoading}
                  disabled={null}
                  aria-disabled={isLoading ? 'true' : null}
                  cursor={isLoading ? 'not-allowed' : 'pointer'}
                  autoFocus
                >
                  {confirmButtonText}
                </Button>
              </ButtonGroup>
            </Flex>
          </ModalFooter>
        )}
      </ModalContent>
    </DSModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  confirmButtonColorScheme: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmButtonVariant: PropTypes.string,
  handleAction: PropTypes.func,
  headerText: PropTypes.string.isRequired,
  modalBody: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  modalWidth: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  showCancel: PropTypes.bool,
  showFooter: PropTypes.bool,
  closeOnOverlayClick: PropTypes.bool,
  isLoading: PropTypes.bool,
  showAutomationWarning: PropTypes.bool,
  createdBy: PropTypes.object,
};

export default Modal;

import firehydrantAPI from 'helpers/firehydrantAPI';
import {
  createMutationHook,
  useDetailQuery,
  useListQuery,
  useQueryClient,
} from 'queries/common';
import { createQueryKeyFactory } from 'queries/common/dependencyBuilder';

const RESOURCE_NAME = 'on_call_schedules';
export const ON_CALL_SCHEDULES = RESOURCE_NAME;

export const useInvalidateOnCallSchedules = () => {
  const queryClient = useQueryClient();

  return (teamId) => {
    const keys = createQueryKeyFactory(RESOURCE_ARRAY(teamId));
    queryClient.invalidateQueries(keys);
  };
};

const RESOURCE_ARRAY = (teamId) => [
  { resource: 'teams', id: teamId },
  { resource: RESOURCE_NAME },
];

export const useOnCallSchedules = (teamId, params = {}, options) => {
  return useListQuery(
    RESOURCE_ARRAY(teamId),
    params,
    () =>
      firehydrantAPI.get(`teams/${teamId}/on_call_schedules`, {
        params: { ...params },
      }),
    { ...options },
  );
};

export const useOnCallSchedule = (teamId, scheduleId, params = {}, options) => {
  return useDetailQuery(
    RESOURCE_ARRAY(teamId),
    scheduleId,
    () =>
      firehydrantAPI.get(`teams/${teamId}/on_call_schedules/${scheduleId}`, {
        params: { ...params },
      }),
    {
      enabled: Boolean(teamId && scheduleId),
      ...options,
    },
  );
};

const createOnCallSchedule = (body) =>
  firehydrantAPI.post(`teams/${body.teamId}/on_call_schedules`, body);

export const useCreateOnCallSchedule = createMutationHook(createOnCallSchedule);

const updateOnCallSchedule = (body) =>
  firehydrantAPI.patch(
    `teams/${body.teamId}/on_call_schedules/${body.id}`,
    body,
  );

export const useUpdateOnCallSchedule = createMutationHook(updateOnCallSchedule);

const deleteOnCallSchedule = (body) =>
  firehydrantAPI.delete(`teams/${body.teamId}/on_call_schedules/${body.id}`);

export const useDeleteOnCallSchedule = createMutationHook(deleteOnCallSchedule);

const codeFenceRegex = new RegExp('```.*?```', 'gs');
export const cleanUpCodeBlocks = (source) => {
  const matches = source.matchAll(codeFenceRegex);
  const newCode = [];
  let cursor = 0;
  let match_number = 1;

  for (let match of matches) {
    match_number = match_number + 1;

    if (cursor === 0 && match.index > 0) {
      newCode.push(source.substring(0, match.index));
    }

    if (cursor > 0 && cursor < match.index) {
      newCode.push(source.substring(cursor, match.index));
    }
    newCode.push('```\n', match[0].substring(3, match[0].length - 3), '\n```');
    cursor = match.index + match[0].length;
  }
  if (cursor < source.length) {
    newCode.push(source.substring(cursor));
  }

  return newCode.join('');
};

import { Text } from '@firehydrant/design-system';
import ToolbarText from './ToolbarText';

const BackendTimingDisplay = ({data}) => {
  if (data.length == 0) {
    return (
      <ToolbarText>
        <Text as="span" color="white" fontWeight="bold">
          Server-Timing data unavailable
        </Text>
      </ToolbarText>
    )
  }

  const initialValues = {
    render: 0,
    objectDuration: 0,
    objectCount: 0,
    sqlDuration: 0,
    sqlCount: 0
  }
  const be = data.reduce((totals, entry) => {
    return (
      {
        render: totals.render + entry.app.duration,
        objectDuration: totals.objectDuration + entry.db.objects.duration,
        objectCount: totals.objectCount + entry.db.objects.count,
        sqlDuration: totals.sqlDuration + entry.db.sql.duration,
        sqlCount: totals.sqlCount + entry.db.sql.count
      }
  )}, initialValues)

  function formatNumber(num) {
    return num.toLocaleString("en-US", { maximumFractionDigits: 0 })
  }

  return (
    <>
      <ToolbarText>
        <Text as="span" color="white" fontWeight="bold">
          {formatNumber(be.render)}ms
        </Text>
        &nbsp;render
      </ToolbarText>
      <ToolbarText>
        <Text as="span" color="white" fontWeight="bold">
          {formatNumber(be.objectDuration)}ms /&nbsp;
          {formatNumber(be.objectCount)}
        </Text>
        &nbsp;objs
      </ToolbarText>
      <ToolbarText>
        <Text as="span" color="white" fontWeight="bold">
          {formatNumber(be.sqlDuration)}ms /&nbsp;
          {formatNumber(be.sqlCount)}
        </Text>
        &nbsp;sql
      </ToolbarText>
    </>
  );
}

export default BackendTimingDisplay;

import firehydrantAPI from 'helpers/firehydrantAPI';
import { labelFormat } from 'helpers/forms';
import internalAPI from 'helpers/internalAPI';

export const loadAsyncOptions = (
  apiPath,
  labelFormatType,
  convertData = true,
  customRequestParam = 'query',
) => {
  return async (loadedOptions) => {
    const {
      data: { data: results },
    } = await firehydrantAPI(
      apiPath,
      loadedOptions ? { params: { [customRequestParam]: loadedOptions } } : {},
    );

    if (labelFormatType) {
      return results.map((value) => labelFormat(value, labelFormatType));
    }

    return convertData ? convertToOptions(results) : results;
  };
};

export const loadAsyncPaginateOptions = (
  apiPath,
  customOptionConversion = null,
  customRequestParam = 'query',
  labelFormatType = null,
  filterOptions = null,
  additionalPrependedOptions = false,
) => {
  return async (value, loadedOptions, params = { page: 1 }) => {
    if (!params.page) {
      params.page = 1;
    }
    const { page } = params;
    const {
      data: { data: results, pagination },
    } = await firehydrantAPI(
      apiPath,
      loadedOptions
        ? { params: { [customRequestParam]: value, ...params } }
        : {},
    );
    const hasMore = pagination.next && pagination.next > page;
    let options = filterOptions ? filterOptions(results) : results;
    options = additionalPrependedOptions
      ? [...additionalPrependedOptions, ...options]
      : options;
    let formattedOptions;
    if (labelFormatType) {
      formattedOptions = options.map((value) =>
        labelFormat(value, labelFormatType),
      );
    } else if (customOptionConversion) {
      formattedOptions = customOptionConversion(options, page);
    } else {
      formattedOptions = convertToOptions(options);
    }
    return {
      options: formattedOptions,
      hasMore,
      additional: {
        ...params,
        page: page + 1,
      },
    };
  };
};

export const loadInternalAPIAsyncPaginateOptions = (
  path,
  labelFormatType,
  filterOptions = null,
  customOptionConversion = null,
  queryKey = 'name',
  additionalPrependedOptions = false,
) => {
  return async (value, loadedOptions, params = { page: 1 }) => {
    if (!params.page) {
      params.page = 1;
    }
    const { page } = params;
    const {
      data: { data, pagination },
    } = await internalAPI
      .get(
        path,
        loadedOptions ? { params: { [queryKey]: value, ...params } } : {},
      )
      .then((response) => {
        return response;
      });

    const hasMore = pagination.next && pagination.next > page;
    const baseOptions = filterOptions ? filterOptions(data) : data;

    const options = additionalPrependedOptions
      ? [...additionalPrependedOptions, ...baseOptions]
      : baseOptions;

    return {
      options: customOptionConversion
        ? customOptionConversion(data)
        : options.map((value) => labelFormat(value, labelFormatType)),
      hasMore,
      additional: {
        ...params,
        page: page + 1,
      },
    };
  };
};

export const convertToOptions = (items) => {
  return items?.map((item) => ({ label: item.name, value: item.id }));
};

import React, { useEffect } from 'react';

import Prism from 'prismjs';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism-coy.css';
import propTypes from 'prop-types';

const JSONCodeBlock = ({ code, language = 'json' }) => {
  useEffect(() => {
    Prism.highlightAll();
  }, [code]);

  const styleOverrides = `
    .json-wrapper {
      overflow: auto;
      width: 100%;
    }
    .json-wrapper span {
      font-family: "FT Polar Mono", monospace';
    }
    .json-wrapper > pre[class*=language-]::before,
    .json-wrapper > pre[class*=language-]::after {
        display: none;
    }
    .json-wrapper span.token.property, span.token.operator {
      color: #490091;
      background: none;
    }

    .json-wrapper span.token.punctuation {
      color: #2B2F36;
      background: none;
    }
    .json-wrapper span.token.string {
      color: #7900F2;
    }
    .json-wrapper pre[class*=language-]>code {
      border: 1px solid #ccccd5;
      background-image: none;
      border-radius: 4px;
      box-shadow: none;
      padding: 8px;
      overflow: scroll;
      max-height: 600px;
    }
  `;

  return (
    <div className="json-wrapper">
      <style>{styleOverrides}</style>
      <pre
        style={{
          boxShadow: 'none',
          margin: '0',
          fontSize: '.75em',
          background: 'none',
        }}
      >
        <code
          className={`language-${language}`}
          style={{
            border: 'none',
            boxShadow: 'none',
            whiteSpace: 'pre-wrap',
            background: 'none',
          }}
        >
          {code}
        </code>
      </pre>
    </div>
  );
};

export default JSONCodeBlock;

JSONCodeBlock.propTypes = {
  code: propTypes.string.isRequired,
  language: propTypes.string,
};

import React from 'react';

import { Button, ButtonGroup } from '@chakra-ui/react';

import AuthorizedComponent from 'components/common/Authorization/AuthorizedComponent';
import MenuDropdown from 'components/common/Form/MenuDropdown';

import analyticsHeader from '../../../../../assets/images/headers/analytics.svg';
import environmentHeader from '../../../../../assets/images/headers/environments.svg';
import functionalityHeader from '../../../../../assets/images/headers/functionalities.svg';
import mttxHeader from '../../../../../assets/images/headers/mttx-analytics-header.png';
import runbookHeader from '../../../../../assets/images/headers/runbooks.svg';
import serviceHeader from '../../../../../assets/images/headers/services.svg';
import statusHeader from '../../../../../assets/images/headers/status-page.svg';

export const getHeaderData = (
  segment,
  navigate,
  isAuthorized,
  handleCreateRunbook,
) => {
  const data = {
    services: {
      imageSource: serviceHeader,
      title: 'Service Catalog',
      helpText:
        'Connect your services to FireHydrant to more quickly coordinate your response efforts and enrich your incidents with helpful data from your existing tools.',
      helpLink:
        'https://support.firehydrant.com/hc/en-us/articles/360057721972-Introduction-to-Service-Catalog-Management-in-FireHydrant',
      buttons: (
        <AuthorizedComponent
          showChildren
          requiredPermissions={['owner', 'member']}
        >
          <ButtonGroup gap="2" isDisabled={!isAuthorized}>
            <MenuDropdown
              keyName="add-service"
              onChange={(e) => {
                e.value.includes('imports')
                  ? window.location.assign(e.value)
                  : navigate(e.value);
              }}
              options={[
                {
                  label: 'Start from scratch',
                  value: '/services/new',
                },
                {
                  label: 'Import from third party',
                  value: '/imports/services',
                },
              ]}
              value={{
                label: 'Add your services',
                value: 'add-service',
              }}
              buttonVariant="solid"
              size="sm"
            />
          </ButtonGroup>
        </AuthorizedComponent>
      ),
    },
    runbooks: {
      title: 'Runbooks',
      helpText:
        'You can create an automated incident management workflow in FireHydrant with Runbooks. Runbooks are flexible sets of steps that allow you to orchestrate workflows across multiple tools like Slack, Status Pages, and your on-call platform. ',
      helpLink:
        'https://support.firehydrant.com/hc/en-us/articles/360058202491-An-introduction-to-Runbooks',
      buttons: (
        <AuthorizedComponent
          showChildren
          requiredPermissions={['owner', 'member']}
        >
          <ButtonGroup>
            <Button
              onClick={handleCreateRunbook}
              size="sm"
              isDisabled={!isAuthorized}
            >
              Add a runbook
            </Button>
          </ButtonGroup>
        </AuthorizedComponent>
      ),
      imageSource: runbookHeader,
    },
    functionalities: {
      title: 'Functionalities',
      helpText:
        'Catalog your user-facing functionalities to more easily describe incidents on your status pages for your users. Connect your functionalities to services to automate statuses and quickly diagnose dependency issues.',
      helpLink:
        'https://firehydrant.com/docs/configuring-firehydrant/introduction-to-service-catalog-management-in-firehydrant#adding-functionalities',
      buttons: (
        <AuthorizedComponent
          showChildren
          requiredPermissions={['owner', 'member']}
        >
          <MenuDropdown
            keyName="add-functionality"
            isDisabled={!isAuthorized}
            onChange={(e) => {
              e.value.includes('imports')
                ? window.location.assign(e.value)
                : navigate(e.value);
            }}
            options={[
              {
                label: 'Start from scratch',
                value: '/functionalities/new',
              },
              {
                label: 'Import from third party',
                value: '/imports/functionalities',
              },
            ]}
            value={{
              label: 'Add your functionalities',
              value: 'add-functionality',
            }}
            buttonVariant="solid"
            size="sm"
          />
        </AuthorizedComponent>
      ),
      imageSource: functionalityHeader,
    },
    environments: {
      title: 'Environments',
      helpText:
        'Use environments to describe high-level categories for your services and functionalities so that teams can quickly understand what areas of your infrastructure are impacted during an incident.',
      helpLink:
        'https://firehydrant.com/docs/configuring-firehydrant/introduction-to-service-catalog-management-in-firehydrant#adding-environments',
      buttons: (
        <AuthorizedComponent
          showChildren
          requiredPermissions={['owner', 'member']}
        >
          <MenuDropdown
            keyName="add-environment"
            onChange={(e) => {
              e.value.includes('imports')
                ? window.location.assign(e.value)
                : navigate(e.value);
            }}
            isDisabled={!isAuthorized}
            options={[
              {
                label: 'Start from scratch',
                value: '/environments/new',
              },
              {
                label: 'Import from third party',
                value: '/imports/environments',
              },
            ]}
            value={{
              label: 'Add an environment',
              value: 'add-environment',
            }}
            buttonVariant="solid"
            size="sm"
          />
        </AuthorizedComponent>
      ),
      imageSource: environmentHeader,
    },
    status_pages: {
      title: 'Status Pages',
      helpText:
        'Create status pages to communicate updates to your users and customers. Using FireHydrant status pages allows you to easily connect your services and functionalities and even create unique status pages to track specific parts of your infrastructure.',
      helpLink:
        'https://firehydrant.com/docs/configuring-firehydrant/firehydrant-status-page-setup/',
      buttons: (
        <AuthorizedComponent
          showChildren
          requiredPermissions={['owner', 'member']}
        >
          <Button
            variant="solid"
            onClick={isAuthorized ? () => navigate('/status_pages/new') : ''}
            size="sm"
            isDisabled={!isAuthorized}
          >
            Create status page
          </Button>
        </AuthorizedComponent>
      ),
      imageSource: statusHeader,
    },
  };
  return data[segment] || null;
};

import Pusher from 'pusher-js';

const tokenDom = document.querySelector('meta[name=csrf-token]');

let pusher = {
  config: {
    auth: {
      headers: {
        'X-CSRF-Token': '',
      },
    },
  },
};

const fireHydrantPusher = (token) => {
  if (tokenDom && token && !pusher.config.auth.headers.Authorization) {
    pusher = new Pusher(window.pusherAppKey, {
      cluster: 'us2',
      forceTLS: true,
      auth: {
        headers: {
          'X-CSRF-Token': tokenDom.content,
          Authorization: token,
        },
      },
    });
  } else if (token !== pusher.config.auth.headers.Authorization) {
    pusher.config.auth.headers.Authorization = token;
  }

  return pusher;
};

export default fireHydrantPusher;

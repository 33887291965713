import React from 'react';
import PropTypes from 'prop-types';
import FormikDropdown from './FormikDropdown';
import { notificationPriorities } from './signalsDropdownValues';

const AlertNotificationPriorityDropdown = ({ labelText, name = "notificationPriority", ...props }) => {
  return (
    <FormikDropdown
      key="notificationPriority"
      options={notificationPriorities}
      label={labelText}
      name={name}
      {...props}
    />
  );
};

AlertNotificationPriorityDropdown.propTypes = {
  labelText: PropTypes.string,
  name: PropTypes.string,
};

export default AlertNotificationPriorityDropdown;

import React, { useEffect, useState } from 'react';

import { Flex, Text } from '@chakra-ui/react';
import { useUserContext } from 'contexts/UserContext/UserContextProvider';
import { useFormikContext } from 'formik';
import { useDebounce } from 'helpers/debounce';
import firehydrantPusher from 'helpers/firehydrantPusher';
import PropTypes from 'prop-types';
import { useCreateSignalStream } from 'queries/signals/signalStream';
import { useSignalDebugger, useSignals } from 'queries/signals/signals';

import DateTime from 'components/common/DateTime';
import JSONCodeBlock from 'components/common/JSONCodeBlock';

const SkeletonGroup = () => (
  <svg
    width="279"
    height="81"
    viewBox="0 0 279 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="16" y="12.5" width="32" height="8" rx="2" fill="#E2E1E7" />
    <rect x="16" y="28.5" width="63.3333" height="8" rx="2" fill="#BDBDCC" />
    <rect
      x="87.3333"
      y="28.5"
      width="63.3333"
      height="8"
      rx="2"
      fill="#E2E1E7"
    />
    <rect
      x="158.667"
      y="28.5"
      width="63.3333"
      height="8"
      rx="2"
      fill="#BDBDCC"
    />
    <rect x="230" y="28.5" width="33" height="8" rx="2" fill="#E2E1E7" />
    <rect x="16" y="44.5" width="49.6667" height="8" rx="2" fill="#E2E1E7" />
    <rect
      x="73.6667"
      y="44.5"
      width="49.6667"
      height="8"
      rx="2"
      fill="#BDBDCC"
    />
    <rect x="131.333" y="44.5" width="33" height="8" rx="2" fill="#E2E1E7" />
    <rect x="172.333" y="44.5" width="33" height="8" rx="2" fill="#E2E1E7" />
    <rect
      x="213.333"
      y="44.5"
      width="49.6667"
      height="8"
      rx="2"
      fill="#BDBDCC"
    />
    <rect x="16" y="60.5" width="28" height="8" rx="2" fill="#BDBDCC" />
  </svg>
);

const MagnifyingGlass = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="55"
    height="38"
    viewBox="0 0 55 38"
    fill="none"
  >
    <path
      d="M28.1124 27.9041C21.2465 27.9041 15.6806 22.3494 15.6806 15.4974C15.6806 8.64537 21.2465 3.0907 28.1124 3.0907C34.9783 3.0907 40.5442 8.64537 40.5442 15.4974C40.5442 22.3494 34.9783 27.9041 28.1124 27.9041Z"
      fill="#F3F3F5"
      stroke="#75748E"
      strokeWidth="0.925873"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.1047 25.1241C29.452 25.2443 28.788 25.3068 28.1127 25.3115C22.6814 25.3115 18.2785 20.9175 18.2785 15.4972C18.2785 10.077 22.6814 5.68298 28.1127 5.68298C29.5079 5.68298 30.8352 5.97294 32.0375 6.49577C34.1267 7.40432 35.8383 9.0161 36.8721 11.0315C37.5592 12.3711 37.9468 13.889 37.9468 15.4972C37.9468 16.9564 37.6277 18.3412 37.0553 19.5859C36.6467 20.4746 36.109 21.2918 35.4662 22.0138"
      fill="white"
    />
    <path
      d="M30.1047 25.1241C29.452 25.2443 28.788 25.3068 28.1127 25.3115C22.6814 25.3115 18.2785 20.9175 18.2785 15.4972C18.2785 10.077 22.6814 5.68298 28.1127 5.68298C29.5079 5.68298 30.8352 5.97294 32.0375 6.49577C34.1267 7.40432 35.8383 9.0161 36.8721 11.0315C37.5592 12.3711 37.9468 13.889 37.9468 15.4972C37.9468 16.9564 37.6277 18.3412 37.0553 19.5859C36.6467 20.4746 36.109 21.2918 35.4662 22.0137"
      stroke="#75748E"
      strokeWidth="0.925873"
      strokeLinecap="round"
    />
    <path
      d="M34.3108 23.1171C33.5136 23.7637 32.613 24.2882 31.6376 24.6621"
      stroke="#75748E"
      strokeWidth="0.925873"
      strokeLinecap="round"
    />
    <path
      d="M17.9075 25.3115L15.6809 27.5336"
      stroke="#75748E"
      strokeWidth="0.925873"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0404 27.1745C16.7427 27.8755 16.7427 29.012 16.0404 29.7129L11.9266 33.8184C11.2242 34.5193 10.0854 34.5193 9.38305 33.8184C8.68067 33.1174 8.68067 31.9809 9.38305 31.28L13.4968 27.1745C14.1992 26.4736 15.338 26.4736 16.0404 27.1745Z"
      fill="#CCCCD5"
      stroke="#75748E"
      strokeWidth="0.925873"
    />
    <path
      d="M14.1963 27.9041L10.1142 31.9779"
      stroke="white"
      strokeWidth="0.925873"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.5245 11.2382C30.5245 15.5335 27.0354 19.0155 22.7314 19.0155C21.886 19.0155 21.0719 18.8812 20.3096 18.6327C21.5539 21.7143 24.5786 23.8896 28.1124 23.8896C32.7567 23.8896 36.5217 20.1322 36.5217 15.4972C36.5217 11.2973 33.4303 7.81793 29.3945 7.20178C30.1115 8.37884 30.5245 9.76039 30.5245 11.2382Z"
      fill="#CCCCD5"
    />
    <path
      d="M27.9269 8.64575C28.3995 8.64575 28.8612 8.69215 29.3077 8.78062M30.6564 9.19242C33.1951 10.2571 34.9778 12.762 34.9778 15.6824"
      stroke="#75748E"
      strokeWidth="0.925873"
      strokeLinecap="round"
    />
    <path
      d="M8.19346 15.6002H11.2275M5.84655 12.7198H12.2205H5.84655ZM3.24885 12.7198H4.07314H3.24885Z"
      stroke="#75748E"
      strokeWidth="0.925873"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.5923 23.7478H44.6263M43.3274 20.4971H49.7014H43.3274ZM51.8627 20.4971H53.4292H51.8627Z"
      stroke="#75748E"
      strokeWidth="0.925873"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SignalsRulePusherWrapper = ({ channelId, children }) => {
  const { currentOrganization } = useUserContext();
  const [matchedSignals, setMatchedSignals] = useState([]);
  useEffect(() => {
    if (channelId) {
      const pusher = firehydrantPusher(currentOrganization?.token);
      const channel = pusher.subscribe(channelId);

      channel.bind('matched-signal', (event) => {
        if (event?.match) {
          setMatchedSignals((existingMatchedSignals) => {
            return [event.signal, ...existingMatchedSignals];
          });
        }
      });
      return () => {
        setMatchedSignals([]);
        pusher.unsubscribe(channelId);
      };
    }
  }, [channelId]);
  return (
    <>
      {children({
        matchedSignals,
      })}
    </>
  );
};

SignalsRulePusherWrapper.propTypes = {
  channelId: PropTypes.string,
  children: PropTypes.func.isRequired,
};

const SignalsRuleTable = ({
  setSelectedSignal,
  selectedSignal,
  setAnnotationsAndTags,
}) => {
  const [signals, setSignals] = useState([]);
  const [channelId, setChannelId] = useState(null);
  const formik = useFormikContext();
  const debouncedExpression = useDebounce(formik.values.expression, 1000);
  const { data: signalsList } = useSignals({
    limit: 100,
  });

  const debuggerSignals = React.useMemo(() => {
    return signalsList?.map((signal) => {
      return {
        ...signal,
        received_at: undefined,
      };
    });
  }, [signalsList]);
  const { mutate: runDebugger } = useSignalDebugger({
    onSuccess: (res) => {
      const signalIds = new Set();
      res?.data?.signals.forEach((s) => {
        signalIds.add(s.id);
      });
      const nextSignals = signalsList?.filter((s) => signalIds.has(s.id));
      setSignals(nextSignals);
    },
    onError: () => {
      setSignals([]);
    },
  });

  // Create a list of all available annotation keys across every signal
  const annotationKeys = debuggerSignals
    ?.reduce((acc, signal) => {
      const keys = Object.keys(signal.annotations);
      keys.forEach((key) => {
        if (!acc.includes(key)) {
          acc.push(key);
        }
      });
      return acc;
    }, [])
    .sort((a, b) => a.localeCompare(b));

  // Create a list of all available tags across every signal
  const tags = debuggerSignals
    ?.reduce((acc, signal) => {
      const signalTags = signal.tags;
      signalTags.forEach((tag) => {
        if (!acc.includes(tag)) {
          acc.push(tag);
        }
      });
      return acc;
    }, [])
    .sort((a, b) => a.localeCompare(b));

  useEffect(() => {
    setAnnotationsAndTags({
      annotations: annotationKeys,
      tags,
    });
  }, [debuggerSignals]);

  const { mutate: createSignalsStream } = useCreateSignalStream({
    onSuccess: (res) => {
      setChannelId(res?.data?.events_channel);
    },
    onError: () => {
      setChannelId(null);
    },
  });

  useEffect(() => {
    if (debouncedExpression) {
      runDebugger({
        expression: debouncedExpression,
        signals: debuggerSignals,
      });
      createSignalsStream({ expression: debouncedExpression });
    }
  }, [debouncedExpression]);
  return (
    <SignalsRulePusherWrapper channelId={channelId}>
      {({ matchedSignals }) => {
        const allSignals =
          debouncedExpression !== ''
            ? [...matchedSignals, ...signals]
            : signalsList;

        return (
          <Flex p="2">
            <Flex
              width="100%"
              border="1px solid"
              borderColor="gray.100"
              borderRadius="md"
              bg="white"
            >
              <Flex direction="column" w="60%" maxH="600px" overflowY="scroll">
                {!allSignals?.length && (
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    h="300px"
                    maxW="80%"
                    alignSelf="center"
                  >
                    <MagnifyingGlass />
                    <Text mt="3" textAlign="center" color="rgba(0, 0, 0, 0.36)">
                      Create a filter to see what events match. Incoming events
                      will also show up as they arrive if they match the
                      expression.
                    </Text>
                  </Flex>
                )}
                {allSignals.map((signal, index) => {
                  return (
                    <Flex
                      key={signal.id}
                      alignItems="center"
                      justifyContent="space-between"
                      borderBottom={
                        index !== signals.length - 1 ? `1px solid` : 'none'
                      }
                      borderColor="gray.100"
                      px="3"
                      borderRadius="md"
                      py="2"
                      cursor="pointer"
                      bg={selectedSignal?.id === signal.id ? '#F7F7F9' : 'none'}
                      onClick={() => {
                        setSelectedSignal(signal);
                      }}
                    >
                      <Text
                        as="span"
                        whiteSpace="nowrap"
                        fontWeight="500"
                        fontSize="6"
                        color="gray.700"
                        fontFamily="mono"
                        isTruncated
                      >
                        {signal.summary}
                      </Text>
                      <Text
                        as="span"
                        ml="3"
                        whiteSpace="nowrap"
                        fontWeight="400"
                        fontSize="7"
                        color="gray.700"
                        fontFamily="mono"
                      >
                        <DateTime
                          timestamp={
                            signal.received_at?.seconds
                              ? new Date(
                                  signal.received_at?.seconds * 1000,
                                ).toISOString()
                              : signal.received_at
                          }
                          format="friendly"
                          withPopover={false}
                        />
                      </Text>
                    </Flex>
                  );
                })}
              </Flex>
              <Flex
                w="40%"
                flexDirection="column"
                flex={1}
                borderLeft="1px solid"
                borderColor="gray.100"
                bg="gray.50"
                py="3"
                borderRightRadius="6px"
              >
                {!selectedSignal && (
                  <Flex flex={1} justifyContent="center" alignItems="center">
                    <SkeletonGroup />
                  </Flex>
                )}
                {selectedSignal && allSignals.length > 0 && (
                  <JSONCodeBlock
                    code={JSON.stringify(selectedSignal, null, 2)}
                  />
                )}
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </SignalsRulePusherWrapper>
  );
};

SignalsRuleTable.propTypes = {
  setAnnotationsAndTags: PropTypes.func.isRequired,
  setSelectedSignal: PropTypes.func.isRequired,
  selectedSignal: PropTypes.object,
};

export default SignalsRuleTable;

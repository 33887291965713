import { Button } from '@chakra-ui/react';

const ToolbarButton = ({children, ...props}) => {
  return (
    <Button
      variant="ghost"
      height="auto"
      minWidth="min-content"
      px={2}
      py={1}
      _hover={{ bg: "#202020"}}
      {...props}
    >
      {children}
    </Button>
  );
}

export default ToolbarButton;

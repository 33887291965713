import React from 'react';

import { Box, Button, ButtonGroup, Flex } from '@chakra-ui/react';
import { Icon } from '@firehydrant/design-system';
import PropTypes from 'prop-types';

import FormV2 from 'components/common/Form/FormV2/FormV2';

import { NotificationIcon } from './NotificationIcon';
import { NotificationLabel } from './SignalsNotificationLabel';
import { NotificationMessage } from './SignalsNotificationMessage';
import { timeOptions } from './helpers/signalsNotificationFormHelpers';

export const NotificationEditRow = ({
  index,
  notificationOptions,
  item,
  formik,
  cancelEdit,
  delayOptions = timeOptions,
  editDestination = false,
}) => {
  return (
    <Flex gap="2" width="100%">
      <Flex flexDir="column" width="100%" gap="2">
        {editDestination || !item.id ? (
          <>
            <FormV2.Dropdown
              name={`notifications[${index}].notification_type`}
              arrayItemIndex={index}
              options={notificationOptions}
              placeholder="Select Notification Type"
              data-testid="notification-type"
              mb="0"
              menuPortalTarget={document.querySelector(
                `#notification-form-${index}`,
              )}
              menuPosition="fixed"
            />
          </>
        ) : (
          <>
            <Flex minWidth="19.2%" alignItems="center" gap="2">
              <NotificationIcon
                type={item.notification_type?.notification_type}
              />
              <NotificationLabel notification={item} />
            </Flex>
            <Box minWidth="35%">
              <NotificationMessage notification={item} />
            </Box>
          </>
        )}

        <FormV2.Dropdown
          name={`notifications[${index}].delay`}
          arrayItemIndex={index}
          options={delayOptions}
          placeholder="Select Delay"
          data-testid="delay"
          mb="0"
          menuPortalTarget={document.querySelector(
            `#notification-form-${index}`,
          )}
          menuPosition="fixed"
        />
        <Flex>
          <Box display="none">
            <FormV2.Input name={`notifications[${index}].id`} />
          </Box>
          <ButtonGroup mt="2">
            <Button
              type="submit"
              size="xs"
              variant="outline"
              leftIcon={<Icon name="check" boxSize="3" />}
            >
              Save
            </Button>
            <Button
              onClick={() => cancelEdit(formik.resetForm)}
              size="xs"
              variant="ghost"
              leftIcon={<Icon name="close" boxSize="3" />}
            >
              Discard
            </Button>
          </ButtonGroup>
        </Flex>
      </Flex>
    </Flex>
  );
};

NotificationEditRow.propTypes = {
  index: PropTypes.number.isRequired,
  notificationOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  item: PropTypes.shape({
    notification_type: PropTypes.shape({
      notification_type: PropTypes.string,
    }),
    delay: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  }).isRequired,
  formik: PropTypes.object.isRequired,
  cancelEdit: PropTypes.func.isRequired,
  editDestination: PropTypes.bool,
  delayOptions: PropTypes.arrayOf(PropTypes.object),
};

import React, { useEffect, useState } from 'react';

import { Box, Flex, Link, Tag, Text } from '@firehydrant/design-system';

import flames from '../../../assets/images/flames.svg';
import ErrorBoundary from '../common/ErrorBoundary';

const Footer = () => {
  const [statusPage, setStatusPage] = useState(null);
  const year = new Date().getFullYear();
  const links = [
    {
      name: 'Subscription Services Agreement',
      link: '/legal/ssa',
    },
    {
      name: 'Privacy Policy',
      link: '/legal/privacy',
    },
    {
      name: 'About',
      link: 'https://firehydrant.com/about-us/',
      external: true,
    },
  ];

  useEffect(() => {
    fetch('https://d5yfqjr6klqt.statuspage.io/api/v2/status.json')
      .then((response) => response.json())
      .then((data) => {
        setStatusPage(data.status);
      });
  }, []);

  return (
    <ErrorBoundary>
      <Flex pt={3} pb={3} pr={4} justifyContent="flex-end">
        <Flex
          fontSize={7}
          direction="column"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Flex align="center" mb={2}>
            <img src={flames} alt="firehydrant logo" />
            <Text as={'span'} fontSize={7} ml={2} fontWeight="medium">
              &copy; {year} FireHydrant, Inc.
            </Text>
            {statusPage?.description && (
              <Tag
                as={Link}
                href="https://status.firehydrant.com"
                rel="noopener noreferrer"
                boxShadow="none"
                variant="status"
                _hover={{ boxShadow: 'none' }}
                ml={2}
                colorScheme={statusPage.indicator === 'none' ? 'green' : 'red'}
              >
                {statusPage.description}
              </Tag>
            )}
          </Flex>
          <Box>
            {links.map((link) => (
              <Text as="span" ml={3} key={link.name}>
                <Link
                  {...(link.external ? { as: Link } : {})}
                  boxShadow="none"
                  fontWeight="medium"
                  fontSize={7}
                  href={link.link}
                  color="gray.700"
                  _hover={{ boxShadow: 'none' }}
                >
                  {link.name}
                </Link>
              </Text>
            ))}
          </Box>
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
};

export default Footer;

import firehydrantAPI from 'helpers/firehydrantAPI';
import { createMutationHook, useApiQuery } from 'queries/common';

export const TEAMS = 'teams';
export const TEAMS_DETAIL = [TEAMS, 'detail'];
export const TEAMS_LIST = [TEAMS, 'list'];
export const useTeams = (params, options = {}) => {
  const query = useApiQuery(
    [...TEAMS_LIST, params],
    () => firehydrantAPI.get('teams', { params }),
    options,
  );

  return {
    ...query,
    data: query.data?.data?.data ?? [],
    pagination: query.data?.data?.pagination ?? {},
  };
};

export const useTeam = (id, options = {}) => {
  const query = useApiQuery(
    [...TEAMS_DETAIL, id],
    () => firehydrantAPI.get(`teams/${id}`),
    {
      enabled: !!id,
      ...options,
    },
  );

  return {
    ...query,
    data: query.data?.data ?? {},
  };
};

const createTeam = (body) => firehydrantAPI.post('teams', body);

export const useCreateTeam = createMutationHook(createTeam);

const updateTeam = (body) => firehydrantAPI.patch(`/teams/${body.id}`, body);

export const useUpdateTeam = createMutationHook(updateTeam);

const deleteTeam = (id) => firehydrantAPI.delete(`teams/${id}`);

export const useDeleteTeam = createMutationHook(deleteTeam);

import {
  AsyncCreatableDropdown,
  AsyncDropdown,
  AsyncPaginateDropdown,
  Dropdown as DSDropdown,
} from '@firehydrant/design-system';

import firehydrantAPI from '../../../../helpers/firehydrantAPI';
import { labelFormat } from '../../../../helpers/forms';
import internalAPI from '../../../../helpers/internalAPI';
import { convertToOptions } from '../helpers';

export const loadAsyncOptions = (
  apiPath,
  labelFormatType,
  convertData = true,
  customRequestParam = 'query',
  groupOptions = null,
  internal = false,
  customOptionConversion = null,
) => {
  return async (loadedOptions) => {
    const apiCall = internal ? internalAPI : firehydrantAPI;
    const {
      data: { data: results },
    } = await apiCall(
      apiPath,
      loadedOptions ? { params: { [customRequestParam]: loadedOptions } } : {},
    );

    if (customOptionConversion) {
      return customOptionConversion(results);
    }

    if (labelFormatType) {
      return results.map((value) => labelFormat(value, labelFormatType));
    }

    if (groupOptions) {
      return groupOptions(results);
    }

    return convertData ? convertToOptions(results) : results;
  };
};

export const loadAsyncPaginateOptions = (
  apiPath,
  customOptionConversion = null,
  customRequestParam = 'query',
  internal = false,
  labelFormatType = null,
) => {
  const apiCall = internal ? internalAPI : firehydrantAPI;
  return async (value, loadedOptions, params = { page: 1 }) => {
    if (!params.page) {
      params.page = 1;
    }
    const { page } = params;
    const {
      data: { data: results, pagination },
    } = await apiCall(
      apiPath,
      loadedOptions
        ? { params: { [customRequestParam]: value, ...params } }
        : {},
    );
    const hasMore = pagination.next && pagination.next > page;

    let options;
    if (labelFormatType) {
      options = results.map((value) => labelFormat(value, labelFormatType));
    } else if (customOptionConversion) {
      options = customOptionConversion(results, page);
    } else {
      options = convertToOptions(results);
    }
    return {
      options,
      hasMore,
      additional: {
        ...params,
        page: page + 1,
      },
    };
  };
};

export const getDropdownComponent = (type) => {
  switch (type) {
    case 'async':
      return AsyncDropdown;
    case 'select':
      return DSDropdown;
    case 'creatable':
      return AsyncCreatableDropdown;
    case 'async-paginate':
      return AsyncPaginateDropdown;
    default:
      return DSDropdown;
  }
};

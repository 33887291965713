import React, { useRef, useState } from 'react';

import {
  Button,
  Flex,
  List,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { trackEvent } from 'helpers/pawPrints';
import { FiChevronDown, FiPlus } from 'react-icons/fi';

import FormV2 from 'components/common/Form/FormV2/FormV2';
import { filterItem } from 'components/common/Form/LiquidSnippetsPopover';

import {
  getAvailableOperators,
  handleVisualExpressionBuilderSubmit,
  snippetConfigurations,
} from './helpers/celHelpers';

const renderSnippetField = (selectedSnippet, formValues) => {
  if (selectedSnippet === 'custom tag') {
    return (
      <FormV2.Input name="value" size="sm" placeholder="Add value" mb="0" />
    );
  }
  if (selectedSnippet.includes('tags')) {
    return (
      <Text fontFamily="mono" color="grey.900" fontSize="5" mb="0">
        {formValues.value}
      </Text>
    );
  }
  const snippetConfig = snippetConfigurations[selectedSnippet];

  if (!snippetConfig) {
    return (
      <FormV2.Input name="value" size="sm" placeholder="Add value" mb="0" />
    );
  }

  const SnippetComponent = snippetConfig.component;
  return <SnippetComponent {...snippetConfig.props} />;
};

export const CELExpressionBuilder = ({
  snippets,
  formikProps,
  defaultSnippet = null,
  buttonText = 'Build an expression',
}) => {
  const { onClose, isOpen, onToggle } = useDisclosure();

  const listRef = useRef();

  const [selectedSnippet, setSelectedSnippet] = useState(defaultSnippet);

  const onSnippetSelect = (snippet) => {
    setSelectedSnippet(snippet);
  };

  const handleExpressionBuilderSuccess = (expression) => {
    formikProps.setFieldValue('expression', expression);
    handleClose();

    trackEvent({
      name: 'Alert Rules',
      params: {
        page: 'User adds an expression from the dropdown expression builder',
        event: `expression builder success - ${buttonText}`,
      },
    });
  };

  const handleSubmit = (values) => {
    handleVisualExpressionBuilderSubmit(
      values,
      formikProps.values.expression,
      handleExpressionBuilderSuccess,
    );
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setSelectedSnippet(defaultSnippet);
    }, 500);
  };

  let listItems = [];
  if (snippets?.children) {
    listItems = snippets.children.map((snippet) => {
      return filterItem({
        snippet,
        onSnippetSelect,
      });
    });
  }

  if (snippets.label === 'Tags') {
    listItems.unshift(
      filterItem({
        snippet: {
          label: 'Use a custom tag',
          snippet: 'custom tag',
        },
        onSnippetSelect,
      }),
    );
  }

  const availableOperators = getAvailableOperators(selectedSnippet);

  return (
    <Popover
      placement="bottom-start"
      isLazy
      closeOnBlur={true}
      minW="300px"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <>
        <PopoverTrigger>
          <Button
            mb={1}
            size="sm"
            variant="outline"
            rightIcon={<FiChevronDown width="4" height="4" color="grey.500" />}
            backgroundColor={isOpen ? 'grey.50' : 'white'}
            onClick={onToggle}
          >
            {buttonText}
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent mr="2">
            <PopoverArrow />
            <PopoverBody p="2" boxShadow="base" rounded="md">
              <>
                {!selectedSnippet && (
                  <List ref={listRef} m="0" p="0" overflowY="auto" maxH="200px">
                    {listItems.length === 0 && (
                      <Text
                        fontSize="sm"
                        color="grey.500"
                        textAlign="center"
                        mb="0"
                      >
                        No snippets available
                      </Text>
                    )}
                    {listItems}
                  </List>
                )}
                {selectedSnippet && (
                  <FormV2
                    size="sm"
                    initialValues={{
                      operator: availableOperators[0],
                      value: selectedSnippet.includes('tags')
                        ? selectedSnippet.replace('signal.tags.', '')
                        : null,
                    }}
                    onSubmit={(values) => {
                      handleSubmit({
                        ...values,
                        selectedSnippet,
                      });
                    }}
                  >
                    {(formik) => (
                      <Flex px="2" maxW="300px">
                        <Flex flexDirection="column" gap="2" width="100%">
                          <Text
                            fontFamily="mono"
                            color="grey.900"
                            fontSize="5"
                            mb="0"
                            wordBreak="break-word"
                          >
                            {selectedSnippet.includes('tag')
                              ? 'signal.tags'
                              : selectedSnippet}
                          </Text>
                          <FormV2.Dropdown
                            name="operator"
                            placeholder="Operator"
                            options={availableOperators}
                            mb="0"
                            size="sm"
                          />
                          {renderSnippetField(selectedSnippet, formik.values)}
                          <Flex>
                            <Button
                              variant="outline"
                              size="xs"
                              type="submit"
                              leftIcon={<FiPlus />}
                              // A bit hacky but using this to allow closing the popover after selecting a snippet
                              autoFocus
                            >
                              Add
                            </Button>
                          </Flex>
                        </Flex>
                      </Flex>
                    )}
                  </FormV2>
                )}
              </>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </>
    </Popover>
  );
};

import * as Yup from 'yup';

import { isoDurationToHumanReadable } from 'components/common/DateTime/helpers';
import {
  findDropdownValue,
  notificationPriorities,
} from 'components/common/Form/signalsDropdownValues';

export const timeDelayOptions = [
  { label: '1 minute', value: 'PT1M' },
  { label: '5 minutes', value: 'PT5M' },
  { label: '10 minutes', value: 'PT10M' },
  { label: '15 minutes', value: 'PT15M' },
  { label: '30 minutes', value: 'PT30M' },
  { label: '45 minutes', value: 'PT45M' },
  { label: '1 hour', value: 'PT60M' },
];

export const policyDelayOptions = [
  { label: 'Immediately', value: 'immediately' },
  { label: 'After', value: 'after' },
];

export const repeatOptions = [...Array(100).keys()].map((val) => ({
  label: `${val + 1}x`,
  value: val + 1,
}));

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string(),
  steps: Yup.array().of(
    Yup.object({
      targets: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.object().nullable(),
          }),
        )
        .min(1, 'Required'),
      timeout: Yup.object()
        .when('delay', {
          is: (delay) =>
            delay && delay.label === 'After' && delay.value === 'after',
          then: Yup.object()
            .required('Required')
            .test('has-value', 'Required', (obj) => obj && obj.value),
        })
        .nullable(),
    }),
  ),
  repetitions: Yup.object()
    .test('has-value', 'Required', (obj, values) => {
      // If the last step has a timeout and handoff is not set,
      // then the repetitions field is required
      if (
        values.parent.steps[values.parent.steps.length - 1]?.timeout &&
        !obj?.value &&
        values?.repetitions
      ) {
        return false;
      } else {
        return true;
      }
    })
    .nullable(),
  handoff_step: Yup.object()
    .test('has-value', 'Required', (obj, values) => {
      // If the last step has a timeout and repetitions is not set,
      // then the handoff field is required
      if (
        values.parent.steps[values.parent.steps.length - 1]?.timeout &&
        !obj?.value &&
        values?.handoff_step
      ) {
        return false;
      } else {
        return true;
      }
    })
    .nullable(),
});

export const rulesValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  expression: Yup.string().required('Required'),
  target: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .test('isRequired', 'Required', (value) => {
      return Boolean(value && value.value);
    })
    .nullable(),
});

export const ruleCreateValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  expression: Yup.string().required('Required'),
  target_type: Yup.string().required('Required'),
  escalationpolicy_target: Yup.object().when('target_type', {
    is: 'EscalationPolicy',

    // Selected value will be an object with label, value, and type as strings
    then: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
      type: Yup.string(),
    }),
  }),
  oncallschedule_target: Yup.object().when('target_type', {
    is: 'OnCallSchedule',
    then: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
      type: Yup.string(),
    }),
  }),

  user_target: Yup.object().when('target_type', {
    is: 'User',
    then: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
      type: Yup.string(),
    }),
  }),
});

export const policyTargetOptions = [
  {
    label: 'On call schedule',
    value: 'Signals::OnCallSchedule',
  },
  {
    label: 'User',
    value: 'Signals::User',
  },
  {
    label: 'Team',
    value: 'Signals::Team',
  },
];

export const buildInitialValues = (selectedPolicy, hasEscalationPolicies) => {
  const initialValues = {
    name: '',
    default: hasEscalationPolicies,
    steps: [
      {
        targets: [],
        timeout: null,
      },
    ],
    handoff_step: null,
    repetitions: null,
  };

  if (selectedPolicy?.id) {
    initialValues.name = selectedPolicy.name;
    initialValues.description = selectedPolicy.description;
    initialValues.default = selectedPolicy.default;
    initialValues.steps = selectedPolicy.steps.map((step) => ({
      targets: step.targets.map((target) => ({
        value: target.id,
        label: target.name,
        target_type: target.type,
      })),
      timeout: {
        value: step.timeout,
        label: isoDurationToHumanReadable(step.timeout),
      },
    }));
    initialValues.id = selectedPolicy.id;
    initialValues.handoff_step = selectedPolicy?.handoff_step && {
      value: selectedPolicy.handoff_step.target.id,
      label: selectedPolicy.handoff_step.target.name,
      target_type: selectedPolicy.handoff_step.target.type,
    };
    initialValues.repetitions = selectedPolicy.repetitions && {
      label: `${selectedPolicy.repetitions}x`,
      value: selectedPolicy.repetitions,
    };

    if (
      !initialValues.handoff_step &&
      !initialValues.repetitions &&
      initialValues?.steps?.length
    ) {
      initialValues.steps[initialValues?.steps?.length - 1].timeout = null;
    }
  }

  return initialValues;
};

export const buildInitialRulesValues = (selectedRule, escalation_policies) => {
  const initialValues = {
    name: '',
    expression: '',
    target: null,
    id: '',
    action: 'notify',
    target_type: 'EscalationPolicy',
  };

  if (escalation_policies?.length > 0) {
    let defaultEp = escalation_policies.find((ep) => ep.default);

    if (!defaultEp) {
      defaultEp = escalation_policies[0];
    }
    initialValues.escalationpolicy_target = {
      value: defaultEp.id,
      label: `${defaultEp.default ? 'Default:' : ''} ${defaultEp.name}`,
      type: 'EscalationPolicy',
    };
  }

  if (selectedRule) {
    initialValues.name = selectedRule.name;
    initialValues.expression = selectedRule.expression;
    initialValues.target = {
      value: selectedRule.target?.id,
      label: selectedRule.target?.name,
    };
    initialValues.target_type = selectedRule.target?.type;
    initialValues.incidentType = selectedRule.incident_type
      ? {
          value: selectedRule.incident_type?.id,
          label: selectedRule.incident_type?.name,
        }
      : null;

    initialValues.id = selectedRule.id;
    const targetTypeField = `${selectedRule.target?.type.toLowerCase()}_target`;
    initialValues[targetTypeField] = {
      value: selectedRule.target?.id,
      label: selectedRule.target?.name,
      type: selectedRule.target?.type,
    };

    if (selectedRule.notification_priority_override) {
      initialValues.notificationPriorityOverride = findDropdownValue(
        selectedRule.notification_priority_override,
        notificationPriorities,
      );
    }
  }
  return initialValues;
};

export const celSnippets = (annotationsAndTags) => {
  const { annotations, tags } = annotationsAndTags;

  let annotationSnippets = [
    {
      label: 'First Annotation',
      snippet: 'signal.annotations[0]',
    },
  ];
  let tagSnippets = [
    {
      label: 'First Tag',
      snippet: 'signal.tags[0]',
    },
  ];

  if (annotations && tags) {
    annotationSnippets = annotations.map((annotation) => ({
      label: annotation,
      snippet: `signal.annotations.${annotation}`,
    }));
    tagSnippets = tags.map((tag) => ({
      label: tag,
      snippet: `signal.tags.${tag}`,
    }));
  }

  return [
    {
      label: 'ID',
      snippet: 'signal.id',
    },
    {
      label: 'Level',
      snippet: 'signal.level',
    },
    {
      label: 'Summary',
      snippet: 'signal.summary',
    },
    {
      label: 'Body',
      snippet: 'signal.body',
    },
    {
      label: 'Annotations',
      children: annotationSnippets,
    },
    {
      label: 'Tags',
      children: tagSnippets,
    },
  ];
};

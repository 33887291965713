import React from 'react';

import { Flex, Text } from '@chakra-ui/react';
import { useOrgNavigate } from 'hooks/useOrgNavigate';

import patchy from '../../../assets/images/patchy-secondary-pose-no-shadow.png';

const Page404 = () => {
  const navigate = useOrgNavigate();
  return (
    <Flex direction="column" justifyContent="center" alignItems="center" mt={7}>
      <img src={patchy} height="224px" />
      <Text fontSize={2} color="grey.900" textAlign="center">
        Oops! We can't find that page.
      </Text>
      <Text fontSize={3} textAlign="center">
        <Text
          as="span"
          onClick={() => navigate(-1)}
          textDecoration="underline"
          cursor="pointer"
        >
          Go back
        </Text>{' '}
        or try one of the navigation links.
      </Text>
    </Flex>
  );
};

export default Page404;

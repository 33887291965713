import FormV2 from 'components/common/Form/FormV2/FormV2';

export const operatorOptions = [
  { label: 'contains', value: '.contains({value})' },
  { label: 'is', value: ' == {value}' },
  { label: 'is not', value: ' != {value}' },
  { label: 'at least', value: ' >= {value}' },
  { label: 'includes', value: 'includes' },
  { label: 'does not include', value: 'does not include' },
];

export const operatorMappings = {
  default: ['is', 'is not', 'contains'],
  level: ['is', 'is not', 'at least'],
  tags: ['includes', 'does not include'],
  annotations: ['is'],
};

export const getAvailableOperators = (selectedSnippet) => {
  let snippetType = 'default';

  if (selectedSnippet) {
    if (selectedSnippet.includes('tag')) {
      snippetType = 'tags';
    } else if (selectedSnippet === 'signal.level') {
      snippetType = 'level';
    } else if (selectedSnippet.includes('annotations')) {
      snippetType = 'annotations';
    }
  }

  return operatorOptions.filter((operator) =>
    operatorMappings[snippetType].includes(operator.label),
  );
};

export const snippetConfigurations = {
  'signal.level': {
    component: FormV2.Dropdown,
    props: {
      name: 'value',
      placeholder: 'Add value',
      size: 'sm',
      options: [
        { label: 'Info', value: '0' },
        { label: 'Warn', value: '1' },
        { label: 'Error', value: '2' },
        { label: 'Fatal', value: '3' },
      ],
    },
  },
  'signal.annotations.private.signals.firehydrant.com/team-id': {
    component: FormV2.Dropdown,
    props: {
      name: 'value',
      type: 'async-paginate',
      asyncUrl: 'teams',
      placeholder: 'Choose a team',
    },
  },
  'signal.annotations.private.signals.firehydrant.com/service-id': {
    component: FormV2.Dropdown,
    props: {
      name: 'value',
      type: 'async-paginate',
      asyncUrl: 'services',
      placeholder: 'Choose a service',
    },
  },
  'signal.annotations.private.signals.firehydrant.com/functionality-id': {
    component: FormV2.Dropdown,
    props: {
      name: 'value',
      type: 'async',
      asyncUrl: 'fh-attributes/values/Infrastructure?type=functionality',
      placeholder: 'Choose a functionality',
      customOptionConversion: (options) => {
        return options.map((item) => ({
          label: item.label,
          value: item.value.value,
        }));
      },
    },
  },

  'signal.annotations.signals.firehydrant.com/transposer-name': {
    component: FormV2.Dropdown,
    props: {
      name: 'value',
      type: 'async',
      asyncUrl: 'signals/transposers',
      placeholder: 'Choose a Transposer',
      customOptionConversion: (options) => {
        return options.map((item) => ({
          label: item.name,
          value: item.slug,
        }));
      },
    },
  },
};

export const handleVisualExpressionBuilderSubmit = (
  builderOutput,
  currentExpression,
  setExpression,
) => {
  const { operator, value, selectedSnippet } = builderOutput;

  let actualValue = value?.value || value;

  // Construct the new expression
  let newExpressionPart;
  const parts = selectedSnippet.split('.');

  if (selectedSnippet.includes('tag')) {
    if (operator.label === 'includes') {
      newExpressionPart = `"${actualValue}" in signal.tags`;
    } else if (operator.label === 'does not include') {
      newExpressionPart = `!("${actualValue}" in signal.tags)`;
    }
  } else if (parts.length > 2) {
    // Remove 'signal.annotations.' prefix to get the actual key
    const prefixToRemove = 'signal.annotations.';
    const key = selectedSnippet.replace(prefixToRemove, '');

    newExpressionPart = `signal.annotations["${key}"].matches("${actualValue}")`;
  } else if (selectedSnippet === 'signal.level') {
    newExpressionPart = `${selectedSnippet}${operator.value.replace(
      '{value}',
      `${actualValue}`,
    )}`;
  } else {
    // Not a nested property, construct the expression normally
    newExpressionPart = `${selectedSnippet}${operator.value.replace(
      '{value}',
      `"${actualValue}"`,
    )}`;
  }

  // Determine if we need to append the new expression or set it as the new one

  const newExpression = currentExpression
    ? `${currentExpression} && ${newExpressionPart}`
    : newExpressionPart;

  // Update the Formik state with the new expression
  setExpression(newExpression);
};

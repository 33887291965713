import { Box, Text } from '@chakra-ui/react';

import TextCopy from './TextCopy';

const IdItem = ({ title, name, id }) => {
  return (
    <Box>
      <Text
        p={0}
        m={0}
        fontSize="xs"
        color="grey.400"
        textTransform="uppercase"
      >
        {title}
      </Text>
      <Text p={0} m={0} color="grey.100">
        {name}
      </Text>
      <TextCopy
        p={0}
        m={0}
        fontSize="xs"
        fontFamily="mono"
        color="blue.500"
        text={id}
      />
    </Box>
  );
};

export default IdItem;

import React from 'react';

import { Avatar, AvatarBadge, Flex, Tooltip } from '@chakra-ui/react';
import { useUserContext } from 'contexts/UserContext/UserContextProvider';
import PropTypes from 'prop-types';

const UserAvatar = ({
  isSelf,
  imageName = 'thumb100.png',
  user,
  withBadge = false,
  size = 'sm',
  disableTooltip = false,
  ...props
}) => {
  const { currentUser, UID } = useUserContext();

  // We use UID to know when we need to force the <img> to refetch
  // This also knows where we should fetch our own avatar or a diffenet users
  // avatar. This will resolve the src to either be
  // /avatars/:your_user_id/thumb100.png?somvervesion
  // OR /avatars/:someones_user_id/thumb100.png
  // We make the check too see if the user is still yourself from a created_by object so we can
  // invalidate the cache if you have updated your avatar
  const appendedVersion = `?${UID}`;
  let src = null;
  if (isSelf && currentUser) {
    src = `/avatars/${currentUser?.id}/${imageName}${appendedVersion}`;
  } else if (!isSelf && user?.id) {
    const isStillSelf = currentUser?.id === user.id;
    src = `/avatars/${user?.id}/${imageName}${
      isStillSelf ? appendedVersion : ''
    }`;
  }

  const name = isSelf ? currentUser?.name : user?.name;

  return (
    <Tooltip
      label={name}
      hasArrow
      arrowSize="8"
      borderRadius="base"
      isDisabled={disableTooltip}
    >
      <Flex as="div" flex="0">
        <Avatar name={name} src={src} size={size} color="white" {...props}>
          {withBadge && <AvatarBadge boxSize="1.25em" bg="green.500" />}
        </Avatar>
      </Flex>
    </Tooltip>
  );
};

UserAvatar.propTypes = {
  isSelf: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  imageName: PropTypes.string,
  withBadge: PropTypes.bool,
  size: PropTypes.string,
};

export default UserAvatar;

import React, { useEffect } from 'react';

import { useAuthContext } from 'contexts/AuthContext/AuthContextProvider';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { useToastContext } from 'components/common/ToastBannerProvider/ToastBannerProvider';

const SessionWrapper = ({ children }) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const isLoggedInSSO = urlParams.get('logged_in');
  const isLoggedOut = urlParams.get('logged_out');
  const { handleLogin } = useAuthContext();

  // Checks login/logout params passed from server side redirects
  // Refresh the login page so that tabs can reload with fresh authenticity tokens
  useEffect(() => {
    if (isLoggedOut) {
      window.location.assign('/sessions/new');
    }
  }, [isLoggedOut]);

  useEffect(() => {
    if (isLoggedInSSO) {
      handleLogin();
    }
  }, [isLoggedInSSO]);

  // Listens for logged in changes and refreshes the page
  useEffect(() => {
    const storageChange = (event) => {
      if (event.key === 'logged_in') {
        if (event.newValue === 'false') {
          window.location.assign('/sessions/new');
        } else {
          window.location.reload(true);
        }
      }
    };
    window.addEventListener('storage', storageChange, false);

    return () => window.removeEventListener('storage', storageChange);
  }, []);

  const { addToast } = useToastContext();
  useEffect(() => {
    if (window.flashData?.length) {
      const [type, message] = window.flashData[0];
      const variants = {
        error: 'alert',
        success: 'success',
      };
      const variant = variants[type];
      if (variant && message) {
        addToast({ variant, message });
      }
    }
  }, []);

  return children;
};

SessionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SessionWrapper;

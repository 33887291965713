import firehydrantAPI from 'helpers/firehydrantAPI';
import { createMutationHook, useApiQuery } from 'queries/common';

export const useSignalRule = ({ teamId, ruleId }) => {
  const query = useApiQuery(
    ['rule', 'detail', ruleId],
    () => firehydrantAPI.get(`teams/${teamId}/signal_rules/${ruleId}`),
    {
      enabled: !!ruleId,
    },
  );

  return {
    ...query,
    data: query.data?.data,
  };
};

const createSignalRules = (body) =>
  firehydrantAPI.post(`teams/${body.teamId}/signal_rules`, body);

export const useCreateSignalRules = createMutationHook(createSignalRules);

const updateSignalRules = (body) =>
  firehydrantAPI.patch(`teams/${body.teamId}/signal_rules/${body.id}`, body);

export const useUpdateSignalRules = createMutationHook(updateSignalRules);

const deleteSignalRule = (body) =>
  firehydrantAPI.delete(
    `teams/${body.teamId}/signal_rules/${body.ruleId}`,
    body,
  );

export const useDeleteSignalRules = createMutationHook(deleteSignalRule);

import React from 'react';

import { forwardRef } from '@chakra-ui/react';
import {
  Box,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@firehydrant/design-system';
import { PropTypes } from 'prop-types';

const CustomButton = forwardRef(
  ({ buttonVariant, isOpen, value, ...props }, ref) => {
    return (
      <Box ref={ref} w="fit-content" pl="1">
        <Button
          bg="grey.100"
          borderRadius="24px"
          className="custom-button"
          color={!buttonVariant ? 'grey.700' : undefined}
          minHeight="24px"
          rightIcon={
            isOpen ? <Icon name="chevronUp" /> : <Icon name="chevronDown" />
          }
          variant={buttonVariant || 'tertiary'}
          {...props}
        >
          {value?.label || '...'}
        </Button>
      </Box>
    );
  },
);

CustomButton.propTypes = {
  buttonVariant: PropTypes.string,
  isOpen: PropTypes.bool,
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  style: PropTypes.object,
};

const PillMenuDropdown = ({
  buttonVariant,
  customOptions,
  disabled,
  onChange,
  keyName,
  options,
  fontSize,
  paddingInlineEnd,
  pointerEvents,
  value,
}) => {
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={CustomButton}
            height="20px"
            isOpen={isOpen}
            value={value}
            buttonVariant={buttonVariant}
            fontSize={fontSize}
            paddingInlineEnd={paddingInlineEnd}
            pointerEvents={pointerEvents}
            disabled={disabled}
          />
          <MenuList>
            {options.map((option, i) => (
              <MenuItem
                bg="white"
                fontSize={fontSize || '7'}
                p="8px 12px"
                key={`${keyName}-${option.value}`}
                onClick={() => {
                  onChange(option, i);
                }}
              >
                {option?.label}
              </MenuItem>
            ))}
            {customOptions}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

PillMenuDropdown.defaultProps = {
  buttonVariant: null,
  customOptions: null,
  fontSize: '7',
  disabled: null,
};

PillMenuDropdown.propTypes = {
  buttonVariant: PropTypes.string,
  customOptions: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  keyName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  paddingInlineEnd: PropTypes.number,
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
};

export default PillMenuDropdown;

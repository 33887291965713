import React from 'react';

import { Tag, TagLabel } from '@chakra-ui/react';

const BetaTag = (props) => {
  return (
    <Tag
      variant="status"
      colorScheme="blue"
      mx={2}
      alignSelf="center"
      verticalAlign="text-top"
      {...props}
    >
      <TagLabel>Beta</TagLabel>
    </Tag>
  );
};

export default BetaTag;

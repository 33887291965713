import React from 'react';

import PropTypes from 'prop-types';
import { Navigate, useLocation, useParams } from 'react-router-dom';

const RedirectWithParameters = ({ to }) => {
  const params = useParams();
  const location = useLocation();

  const replaceId = (route, params) => {
    let replaced = route;
    for (const [key, value] of Object.entries(params)) {
      replaced = replaced.replace(`:${key}`, value);
    }

    return replaced;
  };

  const fullPath = {
    pathname: replaceId(to, params),
    search: location.search,
    hash: location.hash,
  };

  return <Navigate replace to={fullPath} state={location.state} />;
};

export default RedirectWithParameters;

RedirectWithParameters.propTypes = {
  to: PropTypes.string.isRequired,
};

import { Fade, Flex, Text, useClipboard } from '@chakra-ui/react';

const TextCopy = ({ text, ...props }) => {
  // Chakra's Clipboard hook is too slow as it first needs to set state
  // with setValue() and then copy to the clipboard. So instead we'll
  // just use the native browser support and steal the onCopy and hasCopied
  // hooks to handle the UI "Copied!" toggle with fade.
  const { onCopy, hasCopied } = useClipboard('');

  function handleCopy(e, value) {
    // Chakra's default copy to clipboard functionality is too slow
    // so using the default browswer clipboard API instead
    navigator.clipboard.writeText(value);
    onCopy();
  }

  return (
    <Flex alignItems="center">
      <Text
        {...props}
        cursor="pointer"
        onClick={(e) => handleCopy(e, text)}
        _hover={{ bg: 'blue.800' }}
      >
        {text}&nbsp;
      </Text>
      <Fade in={hasCopied} display="flex">
        <Text p={0} m={0} fontSize="xs" color="green.300">
          Copied!
        </Text>
      </Fade>
    </Flex>
  );
};

export default TextCopy;

import React from 'react';

import { Box } from '@firehydrant/design-system';
import PropTypes from 'prop-types';

const Loading = ({ color, textAlign, w, h, ...props }) => {
  return (
    <Box data-testid="Loading" textAlign={textAlign} {...props}>
      <Box
        as="svg"
        viewBox="0 0 100 100"
        width={w}
        height={h}
        sx={{
          fill: 'none',
          stroke: color,
          strokeWidth: 10,
          strokeLinecap: 'round',
          strokeDasharray: '223 999',
          animation: 'spin 1000ms linear infinite',
          '@keyframes spin': {
            '100%': {
              transform: 'rotate(360deg)',
            },
          },
        }}
      >
        <Box as="circle" cx="50" cy="50" r="40" />
      </Box>
    </Box>
  );
};

Loading.propTypes = {
  color: PropTypes.string,
  h: PropTypes.string,
  textAlign: PropTypes.string,
  w: PropTypes.string,
};

Loading.defaultProps = {
  color: 'grey.700',
  h: '100px',
  textAlign: 'center',
  w: '100px',
};

export default Loading;

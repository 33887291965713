import React from 'react';

import { Box, Flex, Tab, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const ScheduleStep = ({
  name,
  isComplete,
  hasError,
  isCurrent,
  isDisabled = false,
}) => {
  return (
    <Tab
      p="0"
      display="flex"
      flex="1"
      borderBottom="none"
      isDisabled={isDisabled}
    >
      <Flex
        flexDirection="column"
        borderBottom="10px"
        borderColor={isComplete || isCurrent ? 'primary.600' : 'gray.100'}
        w="100%"
      >
        <Text
          fontSize="6"
          textAlign="left"
          opacity={isCurrent ? '1.0' : '0.4'}
          color="gray.700"
          lineHeight="20px"
          fontWeight="500"
          mb="2"
        >
          {name}
        </Text>
        <Box
          height="4px"
          bg={
            hasError
              ? 'red.500'
              : isComplete || isCurrent
              ? 'primary.600'
              : 'gray.200'
          }
          borderRadius="10px"
        ></Box>
      </Flex>
    </Tab>
  );
};

ScheduleStep.propTypes = {
  name: PropTypes.string.isRequired,
  isComplete: PropTypes.bool,
  hasError: PropTypes.bool,
  isCurrent: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default ScheduleStep;

import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useOrgNavigate } from 'hooks/useOrgNavigate';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';
import { useEntitlement } from 'queries/entitlements';
import { RiCloseLine } from 'react-icons/ri';

import { useAuthorization } from 'components/common/Authorization/AuthHook';
import EntitlementsModal from 'components/entitlements/components/EntitlementsModal';
import { ENTITLEMENT_TIERS } from 'components/entitlements/constants';
import { useMultiOrgPaths } from 'components/routes/multiOrgRoutesHelpers';

import { getHeaderData } from './constants/onboardingData';

const OnboardingHeader = () => {
  const { onboardingHeaders } = useFlags();
  const navigate = useOrgNavigate();
  const isAuthorized = useAuthorization(['owner', 'member']);
  const isNotViewer = useAuthorization(['owner', 'member', 'collaborator']);
  const { isOpen, onToggle } = useDisclosure();

  const pathname = useMultiOrgPaths();
  const pathSegments = pathname.split('/');
  let lastSegment = pathSegments[pathSegments.length - 1];
  let pathId = pathname.slice(1);

  if (lastSegment === 'services') {
    lastSegment =
      pathSegments[pathSegments.length - 2] === 'mttx_analytics'
        ? 'mttx_analytics'
        : 'services';
  }

  const identifier = `headerHelpDismissed-${lastSegment}`;
  const [dismissed, setDismissed] = useState({
    [identifier]: localStorage.getItem(identifier) === 'true',
  });

  const dismiss = () => {
    setDismissed({ ...dismissed, [identifier]: true });
    localStorage.setItem(identifier, 'true');
  };

  const { data: entitlementsData, isLoading: loadingEntitlements } =
    useEntitlement({
      params: { type: 'quota', name: 'runbooks' },
    });

  const quotaMet = !isEmpty(entitlementsData) && !entitlementsData?.available;
  const handleCreateButton = () => {
    if (quotaMet) {
      onToggle();
    } else {
      navigate('/runbooks/new');
    }
  };

  const pathsToHeaders = {
    runbooks: () =>
      getHeaderData('runbooks', navigate, isAuthorized, handleCreateButton),
    'catalog/services': () =>
      getHeaderData('services', navigate, isAuthorized, handleCreateButton),
    'analytics/mttx_analytics/services': () =>
      getHeaderData(
        'mttx_analytics',
        navigate,
        isAuthorized,
        handleCreateButton,
      ),
    'catalog/functionalities': () =>
      getHeaderData(
        'functionalities',
        navigate,
        isAuthorized,
        handleCreateButton,
      ),
    'catalog/environments': () =>
      getHeaderData('environments', navigate, isAuthorized, handleCreateButton),
    status_pages: () =>
      getHeaderData(
        'status_pages',
        navigate,
        isAuthorized,

        handleCreateButton,
      ),
  };
  const data = pathsToHeaders[pathId] ? pathsToHeaders[pathId]() : null;

  useEffect(() => {
    if (data?.title) {
      localStorage.setItem(identifier, dismissed[identifier]?.toString());
    }
  }, [dismissed]);

  useEffect(() => {
    setDismissed({
      ...dismissed,
      [identifier]: localStorage.getItem(identifier) === 'true',
    });
  }, [pathname]);

  const showAnalytics = () => {
    if (pathId !== 'analytics/incidents_impacts') return true;

    return (
      [ENTITLEMENT_TIERS.enterprise].includes(entitlementsData.tier) &&
      isNotViewer
    );
  };

  // Early returns
  if (dismissed[identifier]) return null;
  if (!onboardingHeaders) return null;
  if (!data) return null;
  if (!showAnalytics()) return null;

  const { buttons, title, helpText, helpLink, imageSource } = data;

  return (
    <>
      {!loadingEntitlements && quotaMet && (
        <EntitlementsModal
          isOpen={isOpen}
          onClose={onToggle}
          quotaLimit={entitlementsData?.maximum}
          name="runbooks"
          tier={entitlementsData?.tier}
        />
      )}
      <Flex
        justifyContent="center"
        flexWrap="wrap"
        bgColor="primary.50"
        width="100%"
        maxHeight="300px"
        borderBottom="1px solid"
        borderColor="gray.200"
        pb="4"
      >
        <Flex maxW="1800px" px={10}>
          <Flex
            alignItems="center"
            flexWrap="wrap"
            width={imageSource ? ['100%', '100%', '100%', '50%'] : '100%'}
            alignContent="center"
          >
            <Flex
              justifyContent="space-between"
              flexWrap="wrap"
              mb="4"
              width="100%"
              alignItems="center"
              pt="3"
            >
              <Text fontWeight="500" mb="0" fontSize="4" lineHeight="3" as="h1">
                {title}
              </Text>
              <Button
                rightIcon={<RiCloseLine />}
                onClick={dismiss}
                variant="ghost"
                display={['flex', 'flex', 'flex', 'none']}
              >
                Dismiss
              </Button>
            </Flex>

            <Text width="100%" mb="4">
              {helpText}
            </Text>
            <ButtonGroup ml="0" mb="4" gap="1">
              {buttons}
              {helpLink && (
                <Button
                  as="a"
                  href={helpLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="sm"
                  variant="outline"
                >
                  Learn more
                </Button>
              )}
            </ButtonGroup>
          </Flex>

          {imageSource && (
            <Flex
              width="50%"
              display={['none', 'none', 'none', 'flex']}
              alignItems="center"
              justifyContent="space-between"
              flexDirection="column"
              pt="3"
            >
              <Button
                rightIcon={<RiCloseLine />}
                onClick={dismiss}
                variant="ghost"
                alignSelf="flex-end"
                display={['none', 'none', 'none', 'flex']}
              >
                Dismiss
              </Button>
              <Box alignSelf="center">
                <img src={imageSource} height="220px" alt={title} />
              </Box>
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default OnboardingHeader;

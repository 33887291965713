import React from 'react';

import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { Icon, InfoIcon } from '@firehydrant/design-system';
import { RiRobot2Fill } from 'react-icons/ri';

export default function AutomationWarning({ createdBy }) {
  // return empty div to take up space
  if (createdBy?.source !== 'firehydrant_bot') return <Flex></Flex>;

  return (
    <Box gap="2" alignItems="center">
      <Text fontSize={7} color="grey.700" mb={1}>
        Created by:
      </Text>{' '}
      <Flex gap="1" alignItems="center">
        <Icon as={RiRobot2Fill} w="5" color="gray.700" mr="1" />
        <Text fontSize="7" fontWeight="semibold" mb="0">
          {createdBy?.name}
        </Text>
        <Tooltip
          label="This resource was created by an automation. Before modifying ensure
                you are wanting to update this resource as it may become out of
                sync."
          hasArrow
          openDelay={500}
        >
          <InfoIcon ml="1" name="info" width="16px" height="16px" />
        </Tooltip>
      </Flex>
    </Box>
  );
}

import React, { useState } from 'react';

import { Box, Button, Card, Flex, Text } from '@chakra-ui/react';
import { Icon } from '@firehydrant/design-system';
import { useFormikContext } from 'formik';

import CELExamplesDrawer from './CELExamplesDrawer';
import FormikCELInput from './FormikCELInput';
import SignalsRuleTable from './SignalsRuleTable';

export const RuleExpressionPanel = () => {
  const [infoDrawerIsOpen, setInfoDrawerIsOpen] = useState(false);
  const [annotationsAndTags, setAnnotationsAndTags] = useState({});
  const [selectedSignal, setSelectedSignal] = useState(null);
  const formikProps = useFormikContext();

  return (
    <Box p="0">
      <Text as="p" fontWeight="400" mb="4">
        To create alerts for this team, add a filter expression below. Any incoming events that match this filter will generate new alerts.
      </Text>

      <Card p="2" position="relative">
        <FormikCELInput
          name="expression"
          placeholder='signal.summary.contains("CPU")'
          selectedSignal={selectedSignal}
          annotationsAndTags={annotationsAndTags}
        />
        <SignalsRuleTable
          expression={formikProps.values.expression}
          setSelectedSignal={setSelectedSignal}
          selectedSignal={selectedSignal}
          setAnnotationsAndTags={setAnnotationsAndTags}
        />
      </Card>

      <CELExamplesDrawer
        isOpen={infoDrawerIsOpen}
        onClose={() => setInfoDrawerIsOpen(false)}
      />
    </Box>
  );
};

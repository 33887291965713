import React from 'react';

import { Flex, Tag, Text } from '@chakra-ui/react';
import trackEvent from 'helpers/pawPrints';

import { useAuthorization } from 'components/common/Authorization/AuthHook';
import BetaTag from 'components/common/BetaTag';
import OrgRouteLink from 'components/common/OrgRouteLink';
import { useMultiOrgPaths } from 'components/routes/multiOrgRoutesHelpers';

export function SubMenu({ children, ...props }) {
  return (
    <Flex flexDirection="column" marginRight={3} {...props}>
      {children}
    </Flex>
  );
}

export function SubMenuHeader({ name, ownerOnly, owner }) {
  if (!owner && ownerOnly) return null;
  return (
    <Flex alignItems="center" minHeight="24px" role="group">
      <Text
        color="grey_deprecated.70"
        flex="1"
        mt="3"
        mb="1"
        ml="8"
        mr="8"
        size="7"
        whiteSpace="nowrap"
        borderBottom={'1px solid'}
        borderColor="grey_deprecated.30"
        textTransform="uppercase"
        fontWeight="500"
        letterSpacing={0.5}
      >
        {name}
      </Text>
    </Flex>
  );
}

export function LeftRailHeader({ name, requiredRoles }) {
  const isAuthorized = useAuthorization(requiredRoles);
  if (!isAuthorized) return null;
  return (
    <Flex
      alignItems="center"
      minHeight="24px"
      role="group"
      data-testid="left-rail-header"
    >
      <Text
        color="gray.900"
        flex="1"
        mt="3"
        mb="1"
        ml="2"
        mr="8"
        size="5"
        whiteSpace="nowrap"
        fontWeight="1"
      >
        {name}
      </Text>
    </Flex>
  );
}

export function LeftRailItem({
  name,
  tag,
  href,
  hrefKey,
  owner,
  ownerOnly,
  devOnly,
  isExternalLink,
  internalTools,
  isUserStaffSession,
  hideStaffSession,
  tier,
  tierRestriction,
  rel,
  target,
  dataMethod,
  requiredRoles,
  keepQuery,
  exactMatch,
  isBeta,
  exclusions = [],
}) {
  const currentPath = useMultiOrgPaths();

  const normalizedCurrentPath = currentPath.endsWith('/')
    ? currentPath
    : `${currentPath}/`;

  const isExcluded = (path, exclusionPatterns) => {
    return exclusionPatterns.some((pattern) => {
      const normalizedPattern = pattern.endsWith('/') ? pattern : `${pattern}/`;
      return path.startsWith(normalizedPattern);
    });
  };

  const excluded = isExcluded(normalizedCurrentPath, exclusions);

  const isActive =
    !excluded &&
    ((exactMatch && normalizedCurrentPath === href) ||
      (!exactMatch && normalizedCurrentPath.startsWith(href)));

  const isAuthorized = useAuthorization(requiredRoles);
  if (!isAuthorized) return null;

  if ((!owner && ownerOnly) || (hideStaffSession && isUserStaffSession)) {
    return null;
  }
  if (tierRestriction && !tierRestriction.includes(tier)) {
    return null;
  }
  if (devOnly && window.environment !== 'development') {
    return null;
  }

  const link = href || internalTools[hrefKey];
  const linkProps = isExternalLink
    ? {
        as: 'a',
        href: link,
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {
        as: OrgRouteLink,
        to: keepQuery ? `${link}${window.location.search}` : link,
      };

  return (
    <Flex
      _active={{
        bg: 'white',
        border: '1px solid',
        borderColor: 'gray.100',
        outline: 'none',
      }}
      _focus={{
        outline: 'none',
      }}
      _hover={{
        bg: 'white',
        _focus: {
          borderColor: 'white',
        },
      }}
      {...(link ? linkProps : {})}
      alignItems="center"
      variant="unstyled"
      bg={isActive ? 'white' : null}
      data-testid="left-rail-item"
      cursor="pointer"
      minHeight="24px"
      role="group"
      rel={rel}
      target={target}
      mt={1}
      py="1"
      px="2"
      pr="1"
      borderRadius="6"
      border="1px solid"
      borderColor="transparent"
      data-method={dataMethod}
      onClick={() => {
        trackEvent({
          name: 'Nav Bar Clicked',
          params: {
            pageTitle: name,
          },
        });
      }}
    >
      <Text
        _groupActive={{
          color: 'gray.900',
        }}
        _groupFocus={{
          color: 'gray.900',
        }}
        _groupHover={{
          color: 'gray.900',
        }}
        color={isActive ? 'gray.900' : 'gray.600'}
        flex="1"
        mb="0"
        ml="2"
        size="6"
        whiteSpace="nowrap"
        isTruncated
      >
        {name} {isBeta && <BetaTag />}
      </Text>
      {tag && (
        <Tag size="sm" ml="2" variant="outline" bg="white">
          {tag}
        </Tag>
      )}
    </Flex>
  );
}

export function SubMenuItem({
  name,
  href,
  hrefKey,
  owner,
  ownerOnly,
  devOnly,
  isExternalLink,
  internalTools,
  isUserStaffSession,
  hideStaffSession,
  tier,
  tierRestriction,
  rel,
  target,
  dataMethod,
  onClick = () => {},
}) {
  const pathname = useMultiOrgPaths();

  if ((!owner && ownerOnly) || (hideStaffSession && isUserStaffSession)) {
    return null;
  }
  if (tierRestriction && !tierRestriction.includes(tier)) {
    return null;
  }
  if (devOnly && window.environment !== 'development') {
    return null;
  }

  const active = pathname.startsWith(href);

  const link = href || internalTools[hrefKey];
  const linkProps = isExternalLink
    ? {
        as: 'a',
        href: link,
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : { as: OrgRouteLink, to: link };

  return (
    <Flex
      _focus={{
        bg: 'gray.100',
        outline: 'none',
        borderColor: 'white',
      }}
      _hover={{
        bg: 'gray.100',
        _focus: {
          bg: 'gray.100',
          outline: 'none',
          borderColor: 'white',
        },
      }}
      {...(link ? linkProps : {})}
      alignItems="center"
      bg={active ? 'gray.100' : null}
      data-testid={active ? 'active-nav-sub-item' : 'nav-sub-item'}
      cursor="pointer"
      minHeight="24px"
      role="group"
      rel={rel}
      target={target}
      data-method={dataMethod}
      onClick={() => {
        onClick();
        trackEvent({
          name: 'Nav Bar Clicked',
          params: {
            pageTitle: name,
          },
        });
      }}
    >
      <Text
        _groupActive={{
          color: 'gray.900',
        }}
        _groupFocus={{
          color: 'gray.700',
        }}
        _groupHover={{
          color: 'gray.700',
        }}
        flex="1"
        mb="0"
        ml="8"
        size="7"
        whiteSpace="nowrap"
      >
        {name}
      </Text>
    </Flex>
  );
}

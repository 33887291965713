import { CloseIcon } from '@firehydrant/design-system';

import ToolbarButton from './ToolbarButton';

const HideButton = ({ hide }) => {
  return (
    <ToolbarButton title="Hide tools" onClick={() => hide()}>
      <CloseIcon boxSize="16px" color="grey.300" />
    </ToolbarButton>
  );
};

export default HideButton;

import React from 'react';

import { Box, Button, MenuButton, forwardRef } from '@chakra-ui/react';
import { Icon, Menu, MenuItem, MenuList } from '@firehydrant/design-system';
import { PropTypes } from 'prop-types';

const DropdownButton = forwardRef(
  ({ buttonVariant, isOpen, value, ...props }, ref) => {
    return (
      <Box ref={ref} pl="1">
        <Button
          rightIcon={
            isOpen ? <Icon name="chevronUp" /> : <Icon name="chevronDown" />
          }
          variant={buttonVariant || 'ghost'}
          {...props}
        >
          {value?.label || '...'}
        </Button>
      </Box>
    );
  },
);

DropdownButton.propTypes = {
  buttonVariant: PropTypes.string,
  isOpen: PropTypes.bool,
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  style: PropTypes.object,
};

const MenuDropdown = ({
  buttonVariant = undefined,
  CustomButton = null,
  disabled = null,
  onChange,
  keyName,
  options,
  fontSize = 6,
  pointerEvents = undefined,
  value = null,
  ...props
}) => {
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={CustomButton || DropdownButton}
            isOpen={isOpen}
            value={value}
            buttonVariant={buttonVariant}
            pointerEvents={pointerEvents}
            disabled={disabled}
            {...props}
          />
          <MenuList>
            {options.map((option, i) => (
              <MenuItem
                bg="white"
                fontSize={fontSize}
                fontWeight="normal"
                p="8px 12px"
                key={`${keyName}-${option.value}`}
                onClick={() => {
                  onChange(option, i);
                }}
              >
                {option?.label}
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

MenuDropdown.propTypes = {
  buttonVariant: PropTypes.string,
  CustomButton: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  keyName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  pointerEvents: PropTypes.string,
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
};

export default MenuDropdown;

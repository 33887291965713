import React from 'react';

import PropTypes from 'prop-types';

import Modal from 'components/common/Modals/Modal';

export const ConfirmModal = ({
  confirmText = 'Confirm',
  modalBody = '',
  headerText = 'Are you sure?',
  isOpen = true,
  onConfirm = () => {},
  onCancel = () => {},
  confirmButtonColorScheme = 'red',
  isLoading = false,
  createdBy = {},
  ...modalProps
}) => {
  return (
    <Modal
      headerText={headerText}
      modalBody={modalBody}
      confirmText={confirmText}
      confirmButtonColorScheme={confirmButtonColorScheme}
      confirmButtonText={confirmText}
      handleAction={onConfirm}
      onClose={onCancel}
      isOpen={isOpen}
      isLoading={isLoading}
      createdBy={createdBy}
      {...modalProps}
    />
  );
};

ConfirmModal.propTypes = {
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func,
  modalBody: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  headerText: PropTypes.string,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default ConfirmModal;

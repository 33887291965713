import React from 'react';

import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputGroup,
} from '@firehydrant/design-system';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

export { isPossiblePhoneNumber } from 'react-phone-number-input';

const FormikPhoneInput = ({
  customError,
  forceShowError,
  formWidth,
  helperText,
  label,
  labelProps,
  mb,
  name,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field, meta, form }) => {
        const isInvalid =
          ((meta.touched || forceShowError) && meta.error) || customError;
        return (
          <FormControl
            w={formWidth}
            mb={mb}
            isReadOnly={props?.isReadOnly}
            isInvalid={isInvalid}
          >
            {label && (
              <Flex
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <FormLabel color="grey.700" {...labelProps}>
                  {label}
                </FormLabel>
              </Flex>
            )}
            <InputGroup
              bg="white"
              sx={{
                border: '1px',
                borderColor: 'grey.100',
                color: 'grey.900',
                borderRadius: '2px',
                py: 2,
                px: 3,
                '.PhoneInputCountry': { border: '0' },
                input: {
                  p: 1,
                  border: 'none',
                  outline: 'none',
                  focus: { border: 'none' },
                },
              }}
            >
              <PhoneInput
                {...field}
                {...props}
                onChange={(value) => {
                  form.setFieldValue('number', value);
                }}
              />
            </InputGroup>
            <Flex justifyContent="space-between">
              {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </Flex>
            {(((meta.touched || forceShowError) && meta.error) ||
              customError) && (
              <FormErrorMessage color="red.500">
                {meta.error || customError}
              </FormErrorMessage>
            )}
          </FormControl>
        );
      }}
    </Field>
  );
};

FormikPhoneInput.propTypes = {
  customError: PropTypes.string,
  forceShowError: PropTypes.bool,
  formWidth: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isReadOnly: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelProps: PropTypes.shape({}),
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
};

FormikPhoneInput.defaultProps = {
  label: null,
  mb: 3,
};

export default FormikPhoneInput;

import React, { Fragment } from 'react';

import loadable from '@loadable/component';
import { useUserContext } from 'contexts/UserContext/UserContextProvider';
import { Navigate, Route, Routes, matchRoutes } from 'react-router-dom';

import LeftRailPageLayout from 'components/layout/LeftRailPageLayout';
import { PROFILE_SUBMENU } from 'components/layout/SideNavigation/NavStructure';
import { useMultiOrgPaths } from 'components/routes/multiOrgRoutesHelpers';

import OrganizationSettingsPage from './OrganizationSettingsPage';
import PasswordSecurityPage from './PasswordSecurityPage';

const Account = loadable(() => import('components/account/Account'));

export const AccountRoutes = () => {
  const { currentOrganization, currentUserOrganizations, currentUser } =
    useUserContext();
  const formattedLocation = {
    pathname: useMultiOrgPaths(),
  };

  const isViewingDefaultOrg =
    currentOrganization.node.id === currentUser?.defaultOrganization?.id;

  const showDefaultBadge =
    currentUserOrganizations?.length > 1 && isViewingDefaultOrg;

  const layoutRoutes = matchRoutes(
    [
      { path: '/account/edit' },
      { path: '/account/password_security' },
      { path: '/account/organization_settings' },
    ],
    formattedLocation,
  );

  const WrapperComponent = layoutRoutes ? LeftRailPageLayout : Fragment;
  const props = layoutRoutes
    ? {
        structure: [
          ...PROFILE_SUBMENU,
          {
            name: currentOrganization?.node?.name,
            href: `/account/organization_settings`,
            requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
            tag: showDefaultBadge ? 'Default' : undefined,
          },
        ],
      }
    : {};

  return (
    <WrapperComponent {...props}>
      <Routes>
        <Route element={<Account />} path="/edit" />
        <Route element={<PasswordSecurityPage />} path="/password_security" />
        <Route
          element={
            <OrganizationSettingsPage
              isViewingDefaultOrg={isViewingDefaultOrg}
            />
          }
          path="/organization_settings"
        />
        <Route path="*" element={<Navigate replace to="/edit" />} />
      </Routes>
    </WrapperComponent>
  );
};

export default AccountRoutes;

import React from 'react';

import {
  AsyncCreatableDropdown,
  AsyncDropdown,
  AsyncPaginateDropdown,
  Box,
  Dropdown as DSDropdown,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Spinner,
} from '@firehydrant/design-system';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import MenuDropdown from './MenuDropdown';
import PillMenuDropdown from './MenuDropdown/PillMenuDropdown';

const FormikDropdown = ({
  customError,
  disableDefaultOnChange = false,
  errorPath,
  forceShowError = false,
  helpText,
  hideLabel = false, // Use only in cases where label is already created outside this component
  hideLoader = false,
  isLoading = false,
  label = null,
  labelFontWeight,
  mb,
  name,
  rightLabelChild,
  type = 'select',
  validate = undefined,
  fitContent = false,
  ...props
}) => {
  const getComponent = () => {
    switch (type) {
      case 'async':
        return AsyncDropdown;
      case 'async-paginate':
        return AsyncPaginateDropdown;
      case 'select':
        return DSDropdown;
      case 'creatable':
        return AsyncCreatableDropdown;
      case 'menu':
        return MenuDropdown;
      case 'pillMenu':
        return PillMenuDropdown;
      default:
        return DSDropdown;
    }
  };
  const Component = getComponent();

  return (
    <Field name={name} validate={validate}>
      {({ field, form, meta }) => {
        const metaErrorPath = errorPath ? meta.error?.[errorPath] : meta.error;
        return (
          <FormControl
            mb={mb}
            isInvalid={
              ((meta.touched || forceShowError) && metaErrorPath) || customError
            }
            isReadOnly={props.isDisabled}
          >
            {!hideLabel && !hideLoader && (
              <Flex alignItems="center" justify="space-between">
                <Flex alignItems="center">
                  {!hideLabel && (
                    <FormLabel
                      htmlFor={name}
                      color="grey.700"
                      fontWeight={labelFontWeight || '600'}
                      hidereadonly={props.hidereadonly}
                    >
                      {label}
                    </FormLabel>
                  )}
                  {isLoading && !hideLoader && (
                    <Spinner
                      data-testid={`${name}-loading`}
                      h="11px"
                      w="11px"
                      ml="6px"
                    />
                  )}
                </Flex>
                <Box>{rightLabelChild}</Box>
              </Flex>
            )}
            <Component
              {...field}
              {...props}
              inputId={name}
              fitContent={fitContent}
              onChange={(opt, index) => {
                if (!disableDefaultOnChange) {
                  form.setFieldValue(name, opt).then(() => form.validateForm());
                }
                if (props.onChange) {
                  props.onChange(opt, index);
                }
              }}
            />
            {helpText && <FormHelperText>{helpText}</FormHelperText>}
            {(((meta.touched || forceShowError) && metaErrorPath) ||
              customError) && (
              <FormErrorMessage color="red.500" data-testid="dropdown-error">
                {metaErrorPath || customError}
              </FormErrorMessage>
            )}
          </FormControl>
        );
      }}
    </Field>
  );
};

FormikDropdown.propTypes = {
  customError: PropTypes.string,
  disableDefaultOnChange: PropTypes.bool,
  errorPath: PropTypes.string,
  forceShowError: PropTypes.bool,
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  hideLabel: PropTypes.bool,
  hideLoader: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelFontWeight: PropTypes.string,
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  rightLabelChild: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
  validate: PropTypes.func,
  fitContent: PropTypes.bool,
};

export default FormikDropdown;

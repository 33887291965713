import React from 'react';

import {
  FormLabel as ChakraFormLabel,
  useFormControlContext,
} from '@chakra-ui/react';

export function FormLabel({
  children,
  isOptional,
  hidereadonly = false,
  ...props
}) {
  const field = useFormControlContext();

  return (
    <ChakraFormLabel {...props} fontWeight={1}>
      {children} {field.isReadOnly && !hidereadonly && `(Read-only)`}{' '}
      {isOptional && `(Optional)`}
    </ChakraFormLabel>
  );
}

import React from 'react';

import { Flex, Icon, Link, Text } from '@firehydrant/design-system';
import { useUserContext } from 'contexts/UserContext/UserContextProvider';
import { RiErrorWarningFill } from 'react-icons/ri';

import ToolbarText from './ToolbarText';

const AccountAccess = () => {
  const {
    isUserStaffSession: accessingAccount,
    currentOrganization,
    account,
  } = useUserContext();

  if (!accessingAccount) return false;

  return (
    <Flex
      p={2}
      backgroundColor="red.700"
      color="white"
      justifyContent="center"
      gap={2}
      borderColor="red.700"
      position="sticky"
      top="0px"
      zIndex="9"
    >
      <Icon as={RiErrorWarningFill} boxSize="16px" />
      <ToolbarText color="white">
        Accessing
        <Text as="span" fontWeight="bold">
          &nbsp;{account.name}&nbsp;(
          {currentOrganization.node.name})&nbsp;
        </Text>
        All actions are logged.
      </ToolbarText>
      <Link
        href="/sessions"
        aria-label="sign-out"
        data-method="delete"
        data-testid="link"
        fontSize={7}
        color="white"
        textDecoration="underline"
      >
        Exit account access
      </Link>
    </Flex>
  );
};

export default AccountAccess;

import { Flex, Text } from '@firehydrant/design-system';
import TextCopy from './TextCopy';

const TimingListItem = ({entry}) => {
  function formatNumber(num) {
    return num.toLocaleString("en-US", { maximumFractionDigits: 0 })
  }

  function statusCodeStyle(code) {
    if (200 <= code && code <= 299) { return "green.500" }
    else if (300 <= code && code <= 399) { return "blue.300" }
    else if (400 <= code && code <= 499) { return "orange.200" }
    else if (500 <= code && code <= 599) { return "red.400" }
    else { return "grey.100" }
  }

  function performanceStyle(ms) {
    if (ms < 400) { return "grey.400" }
    else if (ms < 700) { return "teal.300" }
    else if (ms < 1000) { return "orange.200" }
    else { return "red.400" }
  }

  return (
    <Flex gap={2}>
      <Text m={0} width={10} align="right" color={performanceStyle(entry.duration)} fontSize="xs">{formatNumber(entry.duration)}ms</Text>
      <Text m={0} width={6} color={statusCodeStyle(entry.responseStatus)} fontSize="xs">{entry.responseStatus}</Text>
      <TextCopy m={0} color="grey.200" fontSize="xs" text={entry.name} />
    </Flex>
  );
}

export default TimingListItem;

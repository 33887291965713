import React from 'react';

import { Box, Text } from '@chakra-ui/react';
import { useUserContext } from 'contexts/UserContext/UserContextProvider';

import UpdatePasswordForm from './UpdatePasswordForm';

const PasswordSecurityPage = () => {
  const { currentUser } = useUserContext();

  return (
    <Box pt={4} mb={4}>
      <Text as="h3" color="grey.900" size={3}>
        Password & security
      </Text>
      {!currentUser?.ssoEnabled && <UpdatePasswordForm />}
      {currentUser?.ssoEnabled && (
        <Text>
          Your account is single sign-on enabled. Contact your admin for
          assistance with your account.
        </Text>
      )}
    </Box>
  );
};

export default PasswordSecurityPage;

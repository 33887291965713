import React, { useEffect } from 'react';

import { useUserContext } from 'contexts/UserContext/UserContextProvider';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { usePostHog } from 'posthog-js/react';

// Only record sessions for accounts created within the past 90 days
const isWithinPast90Days = (unixTimestampMillis) => {
  const timestampDate = new Date(unixTimestampMillis);

  const currentDate = new Date();
  const diffTime = Math.abs(currentDate - timestampDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays <= 90;
};

export const SessionReplayWrapper = ({ children }) => {
  const postHog = usePostHog();
  const { sessionReplay } = useFlags();
  const { currentUser } = useUserContext();

  useEffect(() => {
    if (
      (currentUser?.featureFlagIdentity?.account_created_at &&
        isWithinPast90Days(
          currentUser.featureFlagIdentity.account_created_at,
        )) ||
      sessionReplay
    ) {
      postHog.identify(currentUser.id);
      postHog.startSessionRecording();
    }

    return () => {
      postHog.stopSessionRecording();
    };
  }, [currentUser, postHog, sessionReplay]);

  return children;
};

import React, { useRef, useState } from 'react';

import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Input,
  List,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { FiCode, FiSearch } from 'react-icons/fi';

export const filterGroupHeader = ({ group, itemProps = {} }) => (
  <ListItem
    display="flex"
    width="100%"
    px="2"
    py="2"
    m="0"
    alignItems="center"
    key={`filter-group-${group}`}
    {...itemProps}
  >
    <Text mb="0" fontSize="14px" fontWeight="600">
      {group}
    </Text>
  </ListItem>
);

export const filterItem = ({
  snippet,
  searchInput,
  onSnippetSelect,
  itemProps = {},
}) => {
  return (
    <ListItem
      key={snippet.value}
      _focus={{
        borderColor: 'grey.700',
        boxShadow: 'none',
        outline: 'none',
        color: 'grey.800',
      }}
      _hover={{ backgroundColor: 'gray.50' }}
      onKeyDown={(event) => {
        if (event.key === 38) {
          event.target.previousSibling?.focus();
          event.preventDefault();
        }
        if (event.key === 40) {
          event.target.nextSibling?.focus();
          event.preventDefault();
        }
      }}
      onClick={() => {
        onSnippetSelect(snippet.snippet);

        searchInput?.current?.focus();
      }}
      color="grey.900"
      fontWeight="500"
      textAlign="left"
      as="button"
      display="flex"
      width="100%"
      px="2"
      py="2"
      m="0"
      alignItems="center"
      {...itemProps}
    >
      <Text mb="0" fontSize="6">
        {snippet.label}
      </Text>
    </ListItem>
  );
};

export const LiquidSnippetsPopover = ({
  onSnippetSelect,
  snippets,
  buttonText = 'Insert liquid snippet',
}) => {
  const listRef = useRef();
  const searchInput = useRef();
  const [search, setSearch] = useState('');
  const { onClose } = useDisclosure();

  const searchSnippetData = () => {
    const searchTermLower = search?.toLowerCase();

    // Filter snippets based on the search term
    const filteredData = snippets
      .map((categoryObj) => {
        const categoryKey = Object.keys(categoryObj)[0];

        const items = categoryObj[categoryKey].filter((item) => {
          // Check if label or value of snippet contains the search term
          return (
            item.label.toLowerCase().includes(searchTermLower) ||
            item.snippet.toLowerCase().includes(searchTermLower)
          );
        });

        // If there are matching items, update the category object
        if (items.length > 0) {
          return { [categoryKey]: items };
        } else {
          // Exclude categories without matching items
          return null;
        }
      })
      .filter((category) => category !== null);

    return filteredData;
  };

  const filteredFields = searchSnippetData();

  const listItems = filteredFields.map((group) => {
    const groupName = Object.keys(group)[0];
    const groupHeader = filterGroupHeader({ group: groupName });

    // Create a list of ListItems for each filter group
    const fieldsForGroup = group[groupName].map((snippet) => {
      return filterItem({
        snippet,
        searchInput,
        onSnippetSelect,
        itemProps: { pl: '4' },
      });
    });

    return [groupHeader, ...fieldsForGroup];
  });

  return (
    <Popover
      placement="bottom-start"
      isLazy
      initialFocusRef={searchInput}
      onClose={() => {
        setTimeout(() => {
          setSearch('');
        }, 100);
        onClose();
      }}
    >
      <>
        <PopoverTrigger>
          <Button
            mb={1}
            size="sm"
            variant="outline"
            leftIcon={<FiCode width="4" height="4" color="grey.500" />}
          >
            {buttonText}
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent mr="2">
            <PopoverBody p="0">
              <>
                <Flex position="relative">
                  <Box p="2px" position="absolute">
                    <Icon
                      as={FiSearch}
                      position="relative"
                      left="1"
                      zIndex="100"
                      color="grey.400"
                      top="px"
                    />
                  </Box>
                  <Input
                    backgroundColor="grey.50"
                    variant="search"
                    ref={searchInput}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(event) => {
                      if (event.key === 40) {
                        listRef.current?.children[0]?.focus();
                        event.preventDefault();
                      }
                    }}
                    placeholder="Search fields..."
                    size="sm"
                  />
                </Flex>
                <Divider m="0" />
                <List ref={listRef} m="0" p="0" overflowY="auto" maxH="200px">
                  {filteredFields.length === 0 && (
                    <Text px="2" py="2" mb="0" fontSize="6">
                      No results found
                    </Text>
                  )}
                  {listItems}
                </List>
              </>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </>
    </Popover>
  );
};

LiquidSnippetsPopover.propTypes = {
  onSnippetSelect: PropTypes.func.isRequired,
};

export default LiquidSnippetsPopover;

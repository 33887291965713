import React from 'react';

import { Tooltip } from '@chakra-ui/tooltip';
import PropTypes from 'prop-types';

import { useAuthorization } from './AuthHook';

const AuthorizedComponent = ({
  children,
  requiredPermissions = [],
  fallbackComponent,
  showChildren = false,
}) => {
  const isAuthorized = useAuthorization(requiredPermissions);

  // If the user has all the required permissions, render the children
  if (isAuthorized || requiredPermissions.length === 0) {
    return children;
  }

  if (showChildren) {
    return (
      <>
        <Tooltip
          hasArrow
          label="You lack the required permissions for this action"
          shouldWrapChildren
        >
          {children}
        </Tooltip>
      </>
    );
  }

  // If the user does not have the required permissions, render the fallback component if provided
  return fallbackComponent || null;
};

AuthorizedComponent.propTypes = {
  children: PropTypes.node.isRequired,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  fallbackComponent: PropTypes.element,
  showChildren: PropTypes.bool,
};

export default AuthorizedComponent;

import { Text } from '@firehydrant/design-system';

const ToolbarText = ({ children, style, ...props }) => {
  return (
    <Text display="inline" mb="0" color="grey.300" fontSize="xs" {...props}>
      {children}
    </Text>
  );
};

export default ToolbarText;

import React from 'react';

import { Markdown } from '@firehydrant/design-system';

import Drawer from 'components/common/Drawer';

const md = `
FireHydrant allows you to query data and relationships in your event payloads using [Common Expression Language](https://opensource.google/projects/cel) (CEL).

A basic query in FireHydrant includes an \`entity\` with properties available vid dot notation \`entity.property\` and logical operators with a comparison value. The only available entity today is a \`signal\` which represents the incoming events from webhooks. A basic query might look like the following

\`\`\`
// entity.property == "value"
signal.summary == "CPU Utilization Spiking"
\`\`\`

Each event payload has properties that can be evaluated along with a specific value you wish to express. CEL queries can be performed when creating Alert Rules for a team.

## Basic CEL Usage

**********************************Logical Operators**********************************

| Operator | Meaning | Example |
| --- | --- | --- |
| == | Equals | 2 == 2 will return true |
| != | Does not equal | 3 != 2 will return true |
| > | Greater than | 3 > 2 will return true |
| < | Less Than | 2 < 3 will return true |
| && | And | 2 == 2 && 2 != 3 will return true |
| &#124;&#124; | Or | 2 == 3 || 2 == 2 will return true |

**CEL Functions**

| Function | Example |
| --- | --- |
| contains() | signal.summary.contains("CPU") |
| matches() | signal.summary.matches("CPU") or matches(signal.title, "CPU") |
| size() | Check array length: size(signal.images) > 1 Check string length: size(signal.summary) > 3 |
| startsWith() | signal.summary.startsWith("CPU") |
| endsWith() | signal.summary.endsWith("Spiking") |

<aside>
📚 You can check out [the full documentation of CEL](https://chromium.googlesource.com/external/github.com/google/cel-go/+/refs/tags/v0.9.0/README.md) to explore even more about leveraging CEL inside of FireHydrant for querying signals.

</aside>

## Example Signal and CEL Query

\`\`\`
{
  "summary": "CPU Utilization Spiking",
  "body": "The production server is experiencing greater than 99% utilizations of compute.",
  "level": 0,
  "status": 0,
  "images": [
    {
      "src": "https://site.com/images/123.png",
      "alt": "A simple, sample image"
    }
  ],
  "links": [
    {
      "href": "https://site.com/monitors/123",
      "text": "Monitoring Source"
    }
  ],
  "annotations": {
    "policy": "escalatable"
  },
  "tags": ["service:core-application", "env:prod"],
  "received_at": "2023-11-09T18:22:16.000+00:00"
}
\`\`\`

Below is an example CEL expression that would return true on this payload:

\`\`\`
signal.summary.contains("CPU") && signal.level == 2 && signal.annotations["policy"].matches('escalatable')
\`\`\`

In this expression:

- **\`signal\`** refers to the instance of the Signal.
- **\`signal.summary\`** checks if the summary field in the Signal message contains "CPU".
- **\`signal.level\`** checks if the level field in the Signal message equals the enum for “ERROR”. The standard system levels are available here: \`INFO: 0\` , \`WARN: 1\`, \`ERROR: 2\`, & \`FATAL: 3\`
- **\`signal.annotations["policy"].matches('escalatable')\`** accesses the annotations map and checks if there is a key 'policy' with the value "escalatable".
`;

export default function CELExamplesDrawer({ isOpen = true, onClose }) {
  return (
    <Drawer isOpen={isOpen} title="Using CEL" size="xl" onClose={onClose}>
      <>
        <Markdown source={md} disallowedElements={[]} disableScrollable />
      </>
    </Drawer>
  );
}

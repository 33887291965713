import React from 'react';

import { Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const NotificationMessage = ({ notification }) => {
  let text;

  switch (notification.notification_type.notification_type) {
    case 'email':
      text = `Email me at ${notification.notification_type.label}`;
      break;
    case 'slack':
      text = `Send me a Slack message`;
      break;
    case 'sms':
      text = `Text me at ${notification.notification_type.label}`;
      break;
    case 'whats_app':
      text = `Text me at ${notification.notification_type.label}`;
      break;
    case 'voice':
      text = `Call me at ${notification.notification_type.label}`;
      break;
    case 'apns':
      text = `Push notification to ${notification.notification_type.label}`;
      break;
    case 'fcm':
      text = `Push notification to ${notification.notification_type.label}`;
      break;
    default:
      text = null;
  }
  return (
    <Text mb="0" fontSize="6" mr="3" isTruncated maxW="270px">
      {text}
    </Text>
  );
};

NotificationMessage.propTypes = {
  notification: PropTypes.object.isRequired,
};

import React from 'react';

import PropTypes from 'prop-types';

import { loadAsyncPaginateOptions } from 'components/common/Form/helpers';

import FormikDropdown from './FormikDropdown';

const IncidentTypesDropdown = ({ labelText, ...props }) => {
  const loadIncidentOptions = loadAsyncPaginateOptions(`incident_types`);

  return (
    <FormikDropdown
      key="incidentTypes"
      cacheOptions
      defaultOptions
      isSearchable
      loadOptions={loadIncidentOptions}
      label={labelText}
      name="incidentType"
      type="async-paginate"
      {...props}
    />
  );
};

IncidentTypesDropdown.propTypes = {
  labelText: PropTypes.string,
};

export default IncidentTypesDropdown;

import React from 'react';

import { Code } from '@chakra-ui/react';

// This doesn't do much now but we should start using it
// so when we want to make updates it is in one place
const CodeBlock = ({ children }) => {
  return (
    <Code
      fontSize="7"
      fontFamily="mono"
      borderRadius="4px"
      border="1px solid"
      borderColor="gray.400"
      py="2px"
      px="2"
      bg="gray.50"
      color="purple.800"
    >
      {children}
    </Code>
  );
};

export default CodeBlock;

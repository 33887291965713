import React from 'react';

import { Icon } from '@chakra-ui/react';

export default function CalendarIcon(props) {
  return (
    <Icon
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M18.9844 3.97183H18V2H15.9844V3.97183H8.01562V2H6V3.97183H5.01562C4.45312 3.97183 3.96875 4.17527 3.5625 4.58216C3.1875 4.95775 3 5.42723 3 5.99061V20.0282C3 20.5603 3.1875 21.0141 3.5625 21.3897C3.96875 21.7966 4.45312 22 5.01562 22H18.9844C19.5469 22 20.0156 21.7966 20.3906 21.3897C20.7969 21.0141 21 20.5603 21 20.0282V5.99061C21 5.42723 20.7969 4.95775 20.3906 4.58216C20.0156 4.17527 19.5469 3.97183 18.9844 3.97183ZM18.9844 20.0282H5.01562V9.98122H18.9844V20.0282ZM18.9844 8.00939H5.01562V5.99061H18.9844V8.00939ZM12 12.9859H17.0156V18.0094H12V12.9859Z"
        fill="currentColor"
      />
    </Icon>
  );
}

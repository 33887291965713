import React from 'react';

import { components } from 'react-select';

import { ChevronDownIcon, Icon } from '../icons';

export const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    {props.selectProps.style === 'menu' ? (
      <ChevronDownIcon
        sx={{
          transform: props.selectProps.menuIsOpen
            ? 'rotate(180deg)'
            : 'rotate(0deg)',
        }}
      />
    ) : (
      <Icon
        width="14px"
        height="14px"
        name="chevronUp"
        sx={{
          transform: props.selectProps.menuIsOpen
            ? 'rotate(0deg)'
            : 'rotate(180deg)',
        }}
      />
    )}
  </components.DropdownIndicator>
);
